import { gql } from '@apollo/client'
import { GetPayAppForLienWaiverQuery, GetPayAppQuery } from './apollo-operations'

// The following type aliases facilitate the migration from apollo:codegen to graphql-code-generator.
// Eventually those should be replaced by actual fragments since they're used in many files.
export type PayApp = GetPayAppQuery['payApp']
export type LienWaiverPayApp = GetPayAppForLienWaiverQuery['payApp']

export const location = gql`
  fragment LocationProperties on Location {
    id
    createdAt
    nickname
    street1
    street2
    city
    county
    state
    country
    postalCode
    timeZone
    coordinates
  }
`

export const draftLocation = gql`
  fragment DraftLocationProperties on DraftLocation {
    street1
    street2
    city
    county
    state
    country
    postalCode
    coordinates
    timeZone
  }
`

export const companyIntegration = gql`
  fragment CompanyIntegrationProperties on CompanyIntegration {
    id
    type
    archivedAt
    shortName
    longName
    metadata
    importProjectNameSource
  }
`

export const company = gql`
  fragment CompanyProperties on Company {
    id
    name
    nickname
    type
    phoneNumber
    companyIntegrations {
      ...CompanyIntegrationProperties
    }
    defaultShowAuditItemOnSignedPackages
    agingIntervalType
    defaultRetentionPercent
    defaultPayAppDueOnDayOfMonth
    defaultDaysBeforePayAppDue
    enableBillingWorksheets
    allowLumpSumOverbilling
    vendorLienWaiverReminderFrequency
    storedMaterialsCarryoverType
    picture {
      id
      url
    }
    locations {
      ...LocationProperties
    }
  }
  ${companyIntegration}
  ${location}
`

export const signature = gql`
  fragment SignatureProperties on Signature {
    id
    signatureName
    signatureFont
    file {
      id
      url
    }
  }
`

export const minimalUser = gql`
  fragment MinimalUserProperties on User {
    id
    firstName
    lastName
    jobTitle
    email
    policiesAcceptedAt
  }
`

export const minimalContractUser = gql`
  fragment MinimalContractUserProperties on ContractUser {
    id
    role
    user {
      ...MinimalUserProperties
    }
  }
  ${minimalUser}
`

export const user = gql`
  fragment UserProperties on User {
    ...MinimalUserProperties
    companies {
      ...CompanyProperties
    }
    defaultSignature {
      ...SignatureProperties
    }
    tutorialsShown
  }
  ${minimalUser}
  ${company}
  ${signature}
`

export const companyContact = gql`
  fragment CompanyContactProperties on CompanyContact {
    id
    createdAt
    fullName
    email
    companyName
    phoneNumber
    jobTitle
  }
`

export const worksheetLineItem = gql`
  fragment WorksheetLineItemProperties on WorksheetLineItem {
    id
    code
    name
    costCode
    totalValue
    previousBilled
    unitPrice
    unitName
    billedToDate
    sortOrder
    sovLineItem {
      id
    }
  }
`

export const taxGroup = gql`
  fragment TaxGroupProperties on TaxGroup {
    id
    name
    taxPercent
    integrationMappings
    archivedAt
  }
`

export const sovLineItem = gql`
  fragment SovLineItemProperties on SovLineItem {
    id
    code
    sortOrder
    name
    costCode
    isChangeOrder
    changeOrderRequests {
      id
      name
      internalNumber
    }
    changeOrderApprovedDate
    changeOrderEffectiveDate
    originalTotalValue
    latestTotalValue
    previousBilled
    previousMaterialsInStorage
    billedToDate
    defaultRetentionPercent
    preSitelineRetentionHeldOverride
    preSitelineRetention
    unitName
    unitPrice
    worksheetLineItems {
      ...WorksheetLineItemProperties
    }
    taxGroup {
      ...TaxGroupProperties
    }
  }
  ${worksheetLineItem}
  ${taxGroup}
`

export const sovLineItemWithTotals = gql`
  fragment SovLineItemWithTotalsProperties on SovLineItem {
    ...SovLineItemProperties
    billedToDate
    totalRetention
    preSitelineRetention
  }
  ${sovLineItem}
`

export const minimalSov = gql`
  fragment MinimalSovProperties on Sov {
    id
    totalValue
    totalBilled
    totalRetention
    progressComplete
  }
  ${sovLineItem}
`

export const sov = gql`
  fragment SovProperties on Sov {
    ...MinimalSovProperties
    lineItems {
      ...SovLineItemProperties
    }
  }
  ${minimalSov}
  ${sovLineItem}
`

export const sovLineItemGroup = gql`
  fragment SovLineItemGroupProperties on SovLineItemGroup {
    id
    name
    code
  }
`

export const worksheetLineItemProgress = gql`
  fragment WorksheetLineItemProgressProperties on WorksheetLineItemProgress {
    id
    progressBilled
    previousBilled
    totalValue
    worksheetLineItem {
      id
      code
      name
      costCode
      unitName
      unitPrice
      sovLineItem {
        id
      }
    }
  }
`

export const sovLineItemProgress = gql`
  fragment SovLineItemProgressProperties on SovLineItemProgress {
    id
    progressBilled
    currentRetention
    storedMaterialBilled
    materialsInStorageThroughPreviousPayApp
    materialsInStorageThroughCurrentPayApp
    materialsInstalledInCurrentPayApp
    currentBilled
    previousBilled
    previousWorkCompleted
    futureBilled
    previousRetention
    previousRetentionBilled
    totalValue
    retentionHeldPercent
    retentionHeldOverride
    retentionReleased
    amountDuePreTax
    amountDuePostTax
    amountDueTaxAmount
    taxPercent
    lastProgressWithRetentionHeldOverride {
      id
      payApp {
        id
        payAppNumber
      }
    }
    sovLineItem {
      ...SovLineItemProperties
      sovLineItemGroup {
        ...SovLineItemGroupProperties
      }
    }
    worksheetLineItemProgress {
      ...WorksheetLineItemProgressProperties
    }
  }
  ${sovLineItem}
  ${sovLineItemGroup}
  ${worksheetLineItemProgress}
`

export const formTemplateAnnotation = gql`
  fragment FormTemplateAnnotationProperties on FormTemplateAnnotation {
    id
    userVisibleName
    type
    xStart
    yStart
    width
    height
    pageNumber
    fontFamily
    fontColor
    textAlignment
    syncTag
    isOptional
    selectedKey
    defaultValueKey
    prefix
    suffix
    fieldType
    doNotRetainOnReset
    dynamicFieldTag
    copyDefaultValueFromPreviousAnnotationValue
    imageType
  }
`

export const formTemplateAnnotationValue = gql`
  fragment FormTemplateAnnotationValueProperties on FormTemplateAnnotationValue {
    id
    annotation {
      ...FormTemplateAnnotationProperties
    }
    value
    metadata
  }
  ${formTemplateAnnotation}
`

export const payAppEvent = gql`
  fragment PayAppEventProperties on PayAppEvent {
    id
    createdAt
    isAdmin
    createdBy {
      id
      firstName
      lastName
    }
    type
    metadata
  }
`

export const payAppComment = gql`
  fragment PayAppCommentProperties on PayAppComment {
    id
    type
    message
    createdAt
    user {
      id
      isSitelineTeamMember
      firstName
      lastName
    }
  }
`

export const changeOrderRequestEvent = gql`
  fragment ChangeOrderRequestEventProperties on ChangeOrderRequestEvent {
    id
    createdAt
    createdBy {
      id
      firstName
      lastName
    }
    type
    metadata
  }
`

export const changeOrderRequestComment = gql`
  fragment ChangeOrderRequestCommentProperties on ChangeOrderRequestComment {
    id
    message
    createdAt
    user {
      id
      isSitelineTeamMember
      firstName
      lastName
    }
  }
`

export const contractEvent = gql`
  fragment ContractEventProperties on ContractEvent {
    id
    createdAt
    isAdmin
    type
    metadata
    createdBy {
      id
      firstName
      lastName
    }
  }
`

export const contractComment = gql`
  fragment ContractCommentProperties on ContractComment {
    id
    createdAt
    user {
      id
      isSitelineTeamMember
      firstName
      lastName
    }
    type
    message
  }
`

export const rateTableGroup = gql`
  fragment RateTableGroupProperties on RateTableGroup {
    id
    name
    type
  }
`

export const rateTableItem = gql`
  fragment RateTableItemProperties on RateTableItem {
    id
    code
    sortOrder
    description
    unitName
    unitRate
    group {
      id
      name
      type
    }
  }
`

export const rateTableFee = gql`
  fragment RateTableFeeProperties on ContractRateTableFee {
    id
    description
    percent
    group {
      id
    }
    overridesFee {
      id
    }
  }
`

export const rateTableTaxGroup = gql`
  fragment RateTableTaxGroupProperties on ContractRateTableTaxGroup {
    id
    rateTableGroup {
      id
    }
    taxGroup {
      ...TaxGroupProperties
    }
  }
  ${taxGroup}
`

export const rateTable = gql`
  fragment RateTableProperties on RateTable {
    id
    name
    status
    effectiveAt
    expiresAt
    lastEditedAt
    lastEditedBy {
      id
      firstName
      lastName
    }
    groups {
      ...RateTableGroupProperties
    }
    items {
      ...RateTableItemProperties
    }
    company {
      id
    }
    contracts {
      id
      internalProjectNumber
      billingType
      project {
        id
        projectNumber
        name
      }
      payApps {
        id
        hasContractRateTableChanged
      }
      changeOrderRequests {
        id
        hasContractRateTableChanged
      }
    }
    associatedContracts {
      id
      internalProjectNumber
      billingType
      project {
        id
        projectNumber
        name
      }
      rateTable {
        id
      }
      payApps {
        id
        hasContractRateTableChanged
        rateTable {
          id
        }
      }
      changeOrderRequests {
        id
        hasContractRateTableChanged
        rateTable {
          id
        }
      }
    }
  }
  ${rateTableGroup}
  ${rateTableItem}
`
export const minimalRateTable = gql`
  fragment MinimalRateTableProperties on RateTable {
    id
    name
    status
    effectiveAt
    expiresAt
    lastEditedAt
    lastEditedBy {
      id
      firstName
      lastName
    }
    contracts {
      id
    }
    associatedContracts {
      id
    }
  }
`

export const minimalPayApp = gql`
  fragment MinimalPayAppProperties on PayApp {
    id
    timeZone
    payAppDueDate
    internalDueDate
    billingStart
    billingEnd
    billingType
    status
    invoiceReady
    retentionOnly
    currentBilled
    currentRetention
    previousRetentionBilled
    draftSubmittedAt
  }
`

export const minimalPreSitelinePayApp = gql`
  fragment MinimalPreSitelinePayAppProperties on PreSitelinePayApp {
    id
    billingEnd
    status
    currentBilled
    retentionOnly
  }
`

export const minimalWriteSyncOperation = gql`
  fragment MinimalWriteSyncOperationProperties on WriteSyncOperation {
    id
    createdAt
    status
    payload
    result
    integration {
      id
      type
      mappings
      shortName
    }
  }
`

export const payAppNoProgress = gql`
  fragment PayAppNoProgressProperties on PayApp {
    ...MinimalPayAppProperties
    createdAt
    statusChangedAt
    lastSync {
      ...MinimalWriteSyncOperationProperties
    }
    lastSyncIfSuccessful {
      ...MinimalWriteSyncOperationProperties
    }
    lastErpSync {
      ...MinimalWriteSyncOperationProperties
    }
    amountPaid
    isBalanceManuallyClosed
    previousRetentionBilled
    currentRetention
    billingType
    uploadedFile {
      id
      name
    }
    contract {
      id
      roundRetention
      internalProjectNumber
      taxCalculationType
      company {
        ...CompanyProperties
      }
      project {
        id
        projectNumber
        name
      }
    }
    emailedContacts {
      ...CompanyContactProperties
    }
  }
  ${minimalWriteSyncOperation}
  ${minimalPayApp}
  ${company}
  ${companyContact}
  ${location}
`

export const payAppRateTableFee = gql`
  fragment PayAppRateTableFeeProperties on PayAppRateTableFee {
    id
    percent
    description
    group {
      id
    }
    overridesFee {
      id
    }
  }
  ${rateTableFee}
`

export const payAppRateTableGroup = gql`
  fragment PayAppRateTableGroupProperties on PayAppRateTableGroup {
    id
    name
    amountPreFeesPreTax
    amountPostFeesPreTax
    amountPostFeesPostTax
    type
    fees {
      ...PayAppRateTableFeeProperties
    }
    taxGroup {
      ...TaxGroupProperties
    }
  }
  ${payAppRateTableFee}
  ${taxGroup}
`

export const payAppRateTableItem = gql`
  fragment PayAppRateTableItemProperties on PayAppRateTableItem {
    id
    description
    code
    sortOrder
    unitName
    unitRate
    currentBilled
    currentUnitsBilled
    isOneOffItem
    group {
      id
    }
    attachments {
      id
    }
  }
`

export const payAppRateTable = gql`
  fragment PayAppRateTableProperties on PayApp {
    id
    rateTable {
      id
      name
      status
      effectiveAt
      expiresAt
    }
    rateTableFees {
      ...PayAppRateTableFeeProperties
    }
    rateTableGroups {
      ...PayAppRateTableGroupProperties
    }
    rateTableItems {
      ...PayAppRateTableItemProperties
    }
  }
  ${payAppRateTableFee}
  ${payAppRateTableGroup}
  ${payAppRateTableItem}
`

export const storedFile = gql`
  fragment StoredFileProperties on StoredFile {
    id
    type
    url
    name
    size
  }
`

export const vendorContractCommitment = gql`
  fragment VendorContractCommitmentProperties on VendorContractCommitment {
    id
    vendorContract {
      id
      vendor {
        id
        name
      }
      payAppSwornStatements {
        id
        totalCommitmentAmount
      }
    }
    commitmentType
    amount
    date
    number
    payWhenPaid
    paymentTerms
    file {
      ...StoredFileProperties
    }
    closedAt
  }
  ${storedFile}
`

export const payAppSwornStatementVendorUpdate = gql`
  fragment PayAppSwornStatementVendorUpdateProperties on PayAppSwornStatementVendorUpdate {
    id
    updatedAt
    updatedFromIntegrationType
    integrationInvoicesThroughDate
    manuallyUpdatedBy {
      id
    }
  }
`

export const payAppSwornStatementVendor = gql`
  fragment PayAppSwornStatementVendorProperties on PayAppSwornStatementVendor {
    id
    totalCommitmentAmount
    amountPaidToDate
    amountOutstandingToDate
    lastUpdate {
      ...PayAppSwornStatementVendorUpdateProperties
    }
    vendorContract {
      id
      vendor {
        id
        name
      }
    }
  }
  ${payAppSwornStatementVendorUpdate}
`

export const payApp = gql`
  fragment PayAppProperties on PayApp {
    ...PayAppNoProgressProperties
    ...PayAppRateTableProperties
    payAppNumber
    totalRetention
    physicalSignatureRequired
    texturaRejectedAt
    texturaRejectionReason
    progress {
      ...SovLineItemProgressProperties
    }
    formValues {
      ...FormTemplateAnnotationValueProperties
    }
    events {
      ...PayAppEventProperties
    }
  }
  ${payAppNoProgress}
  ${sovLineItemProgress}
  ${formTemplateAnnotationValue}
  ${payAppEvent}
  ${payAppRateTable}
`

export const sovLineItemProgressEvent = gql`
  fragment SovLineItemProgressEventProperties on SovLineItemProgressEvent {
    id
    createdAt
    createdBy {
      id
      firstName
      lastName
      jobTitle
      email
    }
    isAdmin
    type
    metadata
  }
`

export const vendorContact = gql`
  fragment VendorContactProperties on VendorContact {
    id
    fullName
    email
    phoneNumber
    jobTitle
    lowerTierTo {
      id
    }
  }
`

export const minimalVendorContract = gql`
  fragment MinimalVendorContractProperties on VendorContract {
    id
    vendor {
      id
      name
    }
    lowerTierTo {
      id
      vendor {
        id
        name
      }
    }
  }
`

export const minimalLegalDocument = gql`
  fragment MinimalLegalDocumentProperties on LegalDocument {
    id
    createdAt
    name
    periodStart
    periodEnd
    file {
      ...StoredFileProperties
    }
  }
  ${storedFile}
`

export const legalDocumentRequest = gql`
  fragment LegalDocumentRequestProperties on LegalDocumentRequest {
    id
    createdAt
    updatedAt
    vendorContact {
      ...VendorContactProperties
    }
    status
    actionTakenAt
  }
  ${vendorContact}
`

export const legalDocument = gql`
  fragment LegalDocumentProperties on LegalDocument {
    ...MinimalLegalDocumentProperties
    lastSyncIfSuccessful {
      ...MinimalWriteSyncOperationProperties
    }
    lastSendTime
    isUploaded
    uploadedBy {
      id
      firstName
      lastName
      email
    }
    legalDocumentRequests {
      ...LegalDocumentRequestProperties
    }
    vendorContract {
      ...MinimalVendorContractProperties
    }
  }
  ${minimalWriteSyncOperation}
  ${minimalLegalDocument}
  ${legalDocumentRequest}
  ${minimalVendorContract}
`

export const minimalLegalRequirement = gql`
  fragment MinimalLegalRequirementProperties on LegalRequirement {
    id
    name
    updatedAt
    expirationFrequency
    startDate
    endDate
    skippedPeriods
    isVendorRequirement
    type
    timeZone
  }
`

export const minimalComplianceLegalRequirement = gql`
  fragment MinimalComplianceLegalRequirementProperties on LegalRequirement {
    ...MinimalLegalRequirementProperties
    documents {
      id
      file {
        id
        name
        type
      }
    }
    formTemplate {
      id
      userVisibleName
    }
  }
  ${minimalLegalRequirement}
`

export const legalRequirement = gql`
  fragment LegalRequirementProperties on LegalRequirement {
    ...MinimalLegalRequirementProperties
    missingRequiredFields
    formTemplate {
      id
      userVisibleName
      isCustomerReady
    }
    documents {
      ...LegalDocumentProperties
    }
  }
  ${minimalLegalRequirement}
  ${legalDocument}
`

export const vendorLegalRequirement = gql`
  fragment VendorLegalRequirementProperties on VendorLegalRequirement {
    id
    skippedPeriods
    vendorContract {
      ...MinimalVendorContractProperties
    }
    legalRequirement {
      ...LegalRequirementProperties
      contract {
        id
        company {
          id
        }
        project {
          id
          name
        }
        legalRequirementContacts {
          ...CompanyContactProperties
        }
      }
    }
  }
  ${legalRequirement}
  ${companyContact}
`

export const vendorLegalRequirementForTracker = gql`
  fragment VendorLegalRequirementForTrackerProperties on VendorLegalRequirement {
    id
    skippedPeriods
    vendorContract {
      ...MinimalVendorContractProperties
    }
    legalRequirement {
      ...MinimalLegalRequirementProperties
      contract {
        id
        company {
          id
        }
        project {
          id
          name
        }
        legalRequirementContacts {
          ...CompanyContactProperties
        }
      }
      documents {
        ...LegalDocumentProperties
      }
    }
  }
  ${minimalLegalRequirement}
  ${legalDocument}
  ${companyContact}
`

export const minimalIntegration = gql`
  fragment MinimalIntegrationProperties on Integration {
    id
    type
    metadata
    mappings
    shortName
    longName
    isActive
    allowGcPortalManualSovEditing
    companyIntegration {
      id
      metadata
    }
  }
`

export const minimalContract = gql`
  fragment MinimalContractProperties on Contract {
    id
    role
    status
    roundRetention
    internalProjectNumber
    retentionTrackingLevel
    defaultRetentionPercent
    skippedPayAppMonths
    daysBeforePayAppDue
    billingType
    pastPayAppCount
    preSitelineBilled
    vendorNumber
    showAuditItemOnSignedPackages
    storedMaterialsCarryoverType
    paymentTermsType
    paymentTerms
    timeZone
    taxCalculationType
    company {
      id
    }
    integrations {
      ...MinimalIntegrationProperties
    }
  }
  ${location}
  ${minimalIntegration}
`

export const onboardedProjectContractStatus = gql`
  fragment OnboardedProjectContractStatusProperties on OnboardedProjectContractStatus {
    selectedPayAppForms
    onboardedPayAppForms
    selectedPrimaryLienWaivers
    onboardedPrimaryLienWaiverForms
    selectedVendorLienWaivers
    onboardedVendorLienWaiverForms
    selectedChangeOrderRequestForms
    onboardedChangeOrderRequestForms
    selectedChangeOrderLogForms
    onboardedChangeOrderLogForms
    notifiedOnboardedForms
    selectedRateTable
    addedSov
    addedTeammates
    addedGcContacts
    startedBilling
    isComplete
    isReadyForPayAppSubmit
  }
`

export const onboardedProjectVendorsStatus = gql`
  fragment OnboardedProjectVendorsStatusProperties on OnboardedProjectVendorsStatus {
    addedVendorLienWaivers
    addedLegalRequirements
    addedVendors
    addedGcRecipients
    completedOnboarding
  }
`

export const onboardedProjectComplianceStatus = gql`
  fragment OnboardedProjectComplianceStatusProperties on OnboardedProjectComplianceStatus {
    addedLegalRequirements
    addedGcRecipients
    completedOnboarding
  }
`

export const contractForComplianceProjectHome = gql`
  fragment ContractForComplianceProjectHome on Contract {
    id
    internalProjectNumber
    status
    billingType
    complianceStatus
    vendorNumber
    project {
      id
      projectNumber
      name
      bondNumber
      location {
        ...LocationProperties
      }
      generalContractor {
        companyName
        company {
          id
          name
        }
        selectedAddress {
          ...LocationProperties
        }
      }
      owner {
        companyName
        company {
          id
          name
        }
        selectedAddress {
          ...LocationProperties
        }
      }
      architect {
        companyName
        company {
          id
          name
        }
        selectedAddress {
          ...LocationProperties
        }
      }
      bondProvider {
        companyName
        company {
          id
          name
        }
        selectedAddress {
          ...LocationProperties
        }
      }
    }
    defaultGcContacts {
      ...CompanyContactProperties
    }
    company {
      id
    }
    legalRequirements {
      ...LegalRequirementProperties
    }
    legalRequirementContacts {
      ...CompanyContactProperties
    }
    integrations {
      ...MinimalIntegrationProperties
    }
    onboardedProjectComplianceStatus {
      ...OnboardedProjectComplianceStatusProperties
    }
  }
  ${legalRequirement}
  ${companyContact}
  ${minimalIntegration}
  ${location}
  ${onboardedProjectComplianceStatus}
`

export const minimalProject = gql`
  fragment MinimalProjectProperties on Project {
    id
    name
    projectNumber
    timeZone
    generalContractor {
      companyName
      company {
        id
        name
      }
    }
    metadata {
      payAppDueOnDayOfMonth
    }
  }
`

export const payAppAttachmentForBackup = gql`
  fragment PayAppAttachmentForBackupProperties on PayAppAttachment {
    id
    description
    name
    createdAt
    uploadId
    isEmailAttachment
    isInternalOnly
    file {
      ...StoredFileProperties
    }
    sovLineItemProgress {
      id
      storedMaterialBilled
      progressBilled
      currentBilled
      retentionHeldPercent
      sovLineItem {
        id
        sortOrder
        code
        name
        isChangeOrder
        sovLineItemGroup {
          ...SovLineItemGroupProperties
        }
      }
    }
    contractPayAppAttachment {
      id
    }
    rateTableItems {
      ...PayAppRateTableItemProperties
    }
  }
  ${storedFile}
  ${sovLineItemGroup}
  ${payAppRateTableItem}
`

export const payAppAttachment = gql`
  fragment PayAppAttachmentProperties on PayAppAttachment {
    id
    createdAt
    name
    uploadId
    description
    isEmailAttachment
    isInternalOnly
    file {
      ...StoredFileProperties
    }
    sovLineItemProgress {
      ...SovLineItemProgressProperties
    }
    rateTableItems {
      ...PayAppRateTableItemProperties
    }
  }
  ${storedFile}
  ${sovLineItemProgress}
  ${payAppRateTableItem}
`

export const minimalVendor = gql`
  fragment MinimalVendorProperties on Vendor {
    id
    name
    integrationMappings
    internalNotes
    address {
      ...LocationProperties
    }
    phoneNumber
    contacts {
      ...VendorContactProperties
    }
    companyIntegrations {
      id
      type
      label
    }
  }
  ${location}
  ${vendorContact}
`

export const vendor = gql`
  fragment VendorProperties on Vendor {
    ...MinimalVendorProperties
    company {
      id
    }
    vendorContracts {
      ...MinimalVendorContractProperties
      contract {
        id
        project {
          id
        }
      }
    }
  }
  ${minimalVendor}
  ${vendorContact}
  ${minimalVendorContract}
`

export const vendorPreliminaryNotice = gql`
  fragment VendorPreliminaryNoticeProperties on VendorPreliminaryNotice {
    id
    date
    file {
      ...StoredFileProperties
    }
  }
  ${storedFile}
`

export const vendorContract = gql`
  fragment VendorContractProperties on VendorContract {
    ...MinimalVendorContractProperties
    includeInSwornStatements
    preliminaryNotices {
      ...VendorPreliminaryNoticeProperties
    }
    vendor {
      ...MinimalVendorProperties
    }
    vendorLegalRequirements {
      id
      legalRequirement {
        ...MinimalLegalRequirementProperties
      }
    }
  }
  ${vendorPreliminaryNotice}
  ${minimalVendorContract}
  ${minimalVendor}
  ${minimalLegalRequirement}
`

export const lienWaiverRequest = gql`
  fragment LienWaiverRequestProperties on LienWaiverRequest {
    id
    createdAt
    updatedAt
    vendorContact {
      ...VendorContactProperties
      defaultSignature {
        ...SignatureProperties
      }
    }
    status
    actionTakenAt
  }
  ${vendorContact}
  ${signature}
`

export const lienWaiver = gql`
  fragment LienWaiverProperties on LienWaiver {
    id
    type
    timeZone
    claimant
    date
    defaultAmount
    defaultAmountText
    amount
    customer
    lastSendTime
    isJointCheck
    subject
    message
    defaultExceptionAmounts
    defaultExceptionDates
    hasSelectedDefaultVendorContacts
    lastSyncIfSuccessful {
      ...MinimalWriteSyncOperationProperties
    }
    uploadedFile {
      id
      url
      type
      name
      uploadedBy {
        id
        firstName
        lastName
        email
      }
      createdAt
      size
    }
    vendorContract {
      ...MinimalVendorContractProperties
      vendor {
        ...MinimalVendorProperties
        company {
          id
        }
      }
      preliminaryNotices {
        id
        date
        file {
          ...StoredFileProperties
        }
      }
    }
    payApps {
      id
    }
    contract {
      id
      internalProjectNumber
      project {
        id
        name
        projectNumber
      }
      company {
        id
        name
      }
    }
    lienWaiverRequests {
      ...LienWaiverRequestProperties
      statusLogs {
        id
        status
        statusUpdatedAt
      }
    }
    jobLocation {
      ...LocationProperties
    }
    owner
    formValues {
      ...FormTemplateAnnotationValueProperties
    }
    physicalSignatureRequired
    requestAttachments {
      ...StoredFileProperties
    }
    defaultVendorContacts {
      ...VendorContactProperties
    }
  }
  ${minimalWriteSyncOperation}
  ${minimalVendorContract}
  ${minimalVendor}
  ${lienWaiverRequest}
  ${location}
  ${formTemplateAnnotationValue}
  ${storedFile}
  ${vendorContact}
`

export const lienWaiverForTracker = gql`
  fragment LienWaiverForTrackerProperties on LienWaiver {
    id
    type
    date
    defaultAmount
    amount
    lastSendTime
    lastSyncIfSuccessful {
      ...MinimalWriteSyncOperationProperties
    }
    uploadedFile {
      id
      url
      type
      name
      uploadedBy {
        id
        firstName
        lastName
        email
      }
      createdAt
    }
    vendorContract {
      ...MinimalVendorContractProperties
      vendor {
        id
        name
      }
    }
    lienWaiverRequests {
      id
      createdAt
      updatedAt
      vendorContact {
        ...VendorContactProperties
      }
      status
      statusLogs {
        id
        status
        statusUpdatedAt
      }
      actionTakenAt
    }
  }
`

export const vendorContractForLienWaivers = gql`
  fragment VendorContractForLienWaiversProperties on VendorContract {
    ...MinimalVendorContractProperties
    includeInSwornStatements
    vendor {
      id
      name
      integrationMappings
    }
    preliminaryNotices {
      id
    }
    contract {
      id
      internalProjectNumber
      project {
        id
        name
        projectNumber
        generalContractor {
          company {
            id
            name
          }
        }
      }
    }
  }
  ${minimalVendorContract}
`

export const lienWaiverForTrackerCell = gql`
  fragment LienWaiverForTrackerCellProperties on LienWaiver {
    id
    type
    date
    defaultAmount
    amount
    lastSendTime
    timeZone
    lastSyncIfSuccessful {
      ...MinimalWriteSyncOperationProperties
    }
    contract {
      id
      project {
        id
      }
    }
    uploadedFile {
      id
      url
      type
      name
      uploadedBy {
        id
        firstName
        lastName
        email
      }
      createdAt
    }
    lienWaiverRequests {
      id
      createdAt
      updatedAt
      vendorContact {
        ...VendorContactProperties
      }
      status
      statusLogs {
        id
        status
        statusUpdatedAt
      }
      actionTakenAt
    }
  }
`

export const notification = gql`
  fragment NotificationProperties on Notification {
    id
    createdAt
    type
    message
    link
    contract {
      id
      timeZone
      project {
        id
        name
      }
      company {
        id
        nickname
        name
      }
    }
    read
    createdByUserInitials
  }
`

export const amountLineItem = gql`
  fragment AmountLineItemProperties on AmountLineItem {
    id
    description
    amount
    amountDuePreTax
    amountDueTaxAmount
    amountDuePostTax
    taxPercent
    taxGroup {
      ...TaxGroupProperties
    }
    sortOrder
  }
  ${taxGroup}
`

export const companyUserEmailAlias = gql`
  fragment CompanyUserEmailAliasProperties on CompanyUserEmailAlias {
    id
    emailAddress
    isVerified
    verificationCodeExpiresAt
  }
`

export const companyUser = gql`
  fragment CompanyUserProperties on CompanyUser {
    id
    role
    isSitelineAdmin
    status
    permissions
    emailAlias {
      ...CompanyUserEmailAliasProperties
    }
    user {
      ...MinimalUserProperties
    }
    companyLocation {
      id
      nickname
      street1
      city
    }
  }
  ${minimalUser}
  ${companyUserEmailAlias}
`

export const minimalFormTemplate = gql`
  fragment MinimalFormTemplateProperties on FormTemplate {
    id
    isCustomerReady
    userVisibleName
    status
    type
    versions {
      id
      versionNumber
    }
  }
`

export const onboardingFormTemplate = gql`
  fragment OnboardingFormTemplateProperties on FormTemplate {
    id
    status
    isCustomerReady
    userVisibleName
    type
  }
`

export const formTemplate = gql`
  fragment FormTemplateProperties on FormTemplate {
    id
    isCustomerReady
    userVisibleName
    status
    type
    versions {
      id
      versionNumber
      file {
        ...StoredFileProperties
      }
    }
    variants {
      id
      isDefaultVariant
    }
  }
  ${storedFile}
`

export const minimalSubcontractorLienWaiverTemplateSet = gql`
  fragment MinimalSubcontractorLienWaiverTemplateSetProperties on SubcontractorLienWaiverTemplateSet {
    id
    conditionalFinalVariant {
      id
      template {
        ...MinimalFormTemplateProperties
      }
    }
    conditionalProgressVariant {
      id
      template {
        ...MinimalFormTemplateProperties
      }
    }
    unconditionalFinalVariant {
      id
      template {
        ...MinimalFormTemplateProperties
      }
    }
    unconditionalProgressVariant {
      id
      template {
        ...MinimalFormTemplateProperties
      }
    }
  }
  ${minimalFormTemplate}
`

export const subcontractorLienWaiverTemplateSet = gql`
  fragment SubcontractorLienWaiverTemplateSetProperties on SubcontractorLienWaiverTemplateSet {
    id
    conditionalFinalVariant {
      id
      template {
        ...FormTemplateProperties
      }
    }
    conditionalProgressVariant {
      id
      template {
        ...FormTemplateProperties
      }
    }
    unconditionalFinalVariant {
      id
      template {
        ...FormTemplateProperties
      }
    }
    unconditionalProgressVariant {
      id
      template {
        ...FormTemplateProperties
      }
    }
  }
  ${formTemplate}
`

export const companyLienWaiverTemplateSet = gql`
  fragment CompanyLienWaiverTemplateSetProperties on CompanyLienWaiverTemplateSet {
    id
    name
    state
    generalContractor {
      id
      name
    }
    lienWaivers {
      ...MinimalSubcontractorLienWaiverTemplateSetProperties
    }
  }
  ${minimalSubcontractorLienWaiverTemplateSet}
`

export const payAppRequirementGroup = gql`
  fragment PayAppRequirementGroupProperties on PayAppRequirementGroup {
    id
    order
    payAppRequirements {
      id
      groupOrder
      templateVariant {
        id
        template {
          ...MinimalFormTemplateProperties
        }
      }
    }
  }
  ${minimalFormTemplate}
`

export const companyPayAppFormTemplateSet = gql`
  fragment CompanyPayAppFormTemplateSetProperties on CompanyPayAppFormTemplateSet {
    id
    name
    state
    billingType
    generalContractor {
      id
      name
    }
    payAppRequirementGroups {
      ...PayAppRequirementGroupProperties
    }
  }
  ${payAppRequirementGroup}
`

export const companyFormTemplateCollection = gql`
  fragment CompanyFormTemplateCollectionProperties on CompanyFormTemplateCollection {
    id
    name
    state
    billingType
    generalContractor {
      id
      name
    }
    payAppForms {
      ...CompanyPayAppFormTemplateSetProperties
    }
    primaryLienWaivers {
      ...CompanyLienWaiverTemplateSetProperties
    }
    vendorLienWaivers {
      ...CompanyLienWaiverTemplateSetProperties
    }
    changeOrderRequest {
      id
      template {
        ...FormTemplateProperties
      }
    }
    changeOrderLog {
      id
      template {
        ...FormTemplateProperties
      }
    }
  }
  ${companyPayAppFormTemplateSet}
  ${companyLienWaiverTemplateSet}
  ${formTemplate}
`

export const integrationProjectDetails = gql`
  fragment IntegrationProjectDetailsProperties on IntegrationProjectDetails {
    integrationProjectId
    projectName
    projectAddress {
      ...DraftLocationProperties
    }
    projectNumber
    internalProjectNumber
    sitelineLeadPMIds
    internalNotes
  }
  ${draftLocation}
`

export const integrationContract = gql`
  fragment IntegrationContractProperties on IntegrationContract {
    integrationContractId
    contractName
    contractNumber
    contractDate
    latestPayAppNumber
    payAppDueDate
    retentionPercent
    retentionTrackingLevel
    roundRetention
    billingType
    paymentTermsType
    paymentTerms
    preSitelineBilled
    storedMaterialsCarryoverType
  }
`
export const integrationCompany = gql`
  fragment IntegrationCompanyProperties on IntegrationCompany {
    companyName
    companyAddress {
      ...DraftLocationProperties
    }
  }
  ${draftLocation}
`

export const integrationTaxGroup = gql`
  fragment IntegrationTaxGroupProperties on IntegrationTaxGroup {
    integrationTaxGroupId
    name
    taxPercent
  }
`

export const importProjectOnboardingMetadata = gql`
  fragment ImportProjectOnboardingMetadataProperties on ImportProjectOnboardingMetadata {
    type
    companyIntegrationId
    integrationAssociatedCompanyId
    project {
      ...IntegrationProjectDetailsProperties
    }
    contract {
      ...IntegrationContractProperties
    }
    generalContractor {
      ...IntegrationCompanyProperties
    }
    owner {
      ...IntegrationCompanyProperties
    }
    architect {
      ...IntegrationCompanyProperties
    }
    taxCalculationType
    taxGroup {
      ...IntegrationTaxGroupProperties
    }
  }
  ${integrationProjectDetails}
  ${integrationContract}
  ${integrationCompany}
  ${integrationTaxGroup}
`

export const integrationProjectSummary = gql`
  fragment IntegrationProjectSummaryProperties on IntegrationProjectSummary {
    integrationProjectId
    integrationContractId
    integrationAssociatedCompanyId
    projectName
    projectNumber
    generalContractorName
    contractName
    sitelineProjectId
    sitelineContractId
  }
`

export const changeOrderRequestRateTableFee = gql`
  fragment ChangeOrderRequestRateTableFeeProperties on ChangeOrderRequestRateTableFee {
    id
    percent
    description
    group {
      id
    }
    overridesFee {
      id
    }
  }
  ${rateTableFee}
`

export const changeOrderRequestRateTableGroup = gql`
  fragment ChangeOrderRequestRateTableGroupProperties on ChangeOrderRequestRateTableGroup {
    id
    name
    amountPreFeesPreTax
    amountPostFeesPreTax
    amountPostFeesPostTax
    type
    fees {
      ...ChangeOrderRequestRateTableFeeProperties
    }
    taxGroup {
      ...TaxGroupProperties
    }
  }
  ${changeOrderRequestRateTableFee}
  ${taxGroup}
`

export const changeOrderRequestRateTableItem = gql`
  fragment ChangeOrderRequestRateTableItemProperties on ChangeOrderRequestRateTableItem {
    id
    description
    code
    sortOrder
    unitName
    unitRate
    currentPriced
    currentUnitsPriced
    isOneOffItem
    group {
      id
    }
  }
`

export const changeOrderRequestRateTable = gql`
  fragment ChangeOrderRequestRateTableProperties on ChangeOrderRequest {
    id
    hasContractRateTableChanged
    haveContractFeesChanged
    haveContractTaxesChanged
    rateTable {
      ...MinimalRateTableProperties
    }
    rateTableFees {
      ...ChangeOrderRequestRateTableFeeProperties
    }
    rateTableGroups {
      ...ChangeOrderRequestRateTableGroupProperties
    }
    rateTableItems {
      ...ChangeOrderRequestRateTableItemProperties
    }
  }
  ${minimalRateTable}
  ${changeOrderRequestRateTableFee}
  ${changeOrderRequestRateTableGroup}
  ${changeOrderRequestRateTableItem}
`

export const changeOrderRequestAttachment = gql`
  fragment ChangeOrderRequestAttachmentProperties on ChangeOrderRequestAttachment {
    id
    createdAt
    name
    uploadId
    description
    isInternalOnly
    file {
      ...StoredFileProperties
    }
  }
  ${storedFile}
`

export const minimalChangeOrderRequest = gql`
  fragment MinimalChangeOrderRequestProperties on ChangeOrderRequest {
    id
    createdAt
    name
    internalNumber
    generalContractorNumber
    amount
    proposedAmount
    unitPrice
    unitName
    proposedAt
    scheduleImpact
    proceedingWithWork
    status
    statusChangedAt
    reason
    importedAt
    visibleToGc
    emailedContacts {
      ...CompanyContactProperties
    }
    linkedChangeOrderRequests {
      id
      internalNumber
      generalContractorNumber
      status
      amount
      sovLineItems {
        id
      }
      name
    }
    sovLineItems {
      id
    }
    attachments {
      id
    }
    approvedScoFile {
      id
    }
    contract {
      id
      changeOrderRequestTemplate {
        id
        isCustomerReady
      }
    }
  }
  ${companyContact}
`

export const changeOrderRequest = gql`
  fragment ChangeOrderRequestProperties on ChangeOrderRequest {
    ...MinimalChangeOrderRequestProperties
    ...ChangeOrderRequestRateTableProperties
    missingRequiredFields
    approvedScoFile {
      ...StoredFileProperties
    }
    attachments {
      ...ChangeOrderRequestAttachmentProperties
    }
    sovLineItems {
      id
      name
      code
      latestTotalValue
      unitName
      unitPrice
    }
  }
  ${minimalChangeOrderRequest}
  ${changeOrderRequestRateTable}
  ${changeOrderRequestAttachment}
  ${storedFile}
`

export const integrationCustomer = gql`
  fragment IntegrationCustomerProperties on IntegrationCustomer {
    integrationCustomerId
    name
    code
  }
`

export const userEducationCampaign = gql`
  fragment UserEducationCampaignProperties on UserEducationCampaign {
    id
    name
    showNewTag
    title
    message
    buttonLabel
    buttonLink
  }
`

export const sovChangeSet = gql`
  fragment SovChangeSetProperties on SovChangeSet {
    additions {
      new {
        code
        name
        costCode
        originalTotalValue
        latestTotalValue
        previousBilled
        isChangeOrder
        sortOrder
        changeOrderApprovedDate
        changeOrderEffectiveDate
        sovLineItemGroupId
        previousStoredMaterials
        previousMaterialsInStorage
        unitName
        unitPrice
        preSitelineRetentionHeldOverride
        defaultRetentionPercent
        taxGroupId
      }
    }
    updates {
      oldId
      new {
        code
        name
        costCode
        originalTotalValue
        latestTotalValue
        previousBilled
        isChangeOrder
        sortOrder
        changeOrderApprovedDate
        changeOrderEffectiveDate
        sovLineItemGroupId
        previousStoredMaterials
        previousMaterialsInStorage
        unitName
        unitPrice
        preSitelineRetentionHeldOverride
        defaultRetentionPercent
        taxGroupId
      }
    }
    deletions {
      oldId
    }
    groupAdditions {
      newId
      new {
        name
        code
      }
    }
    groupUpdates {
      oldId
      new {
        name
        code
      }
    }
    groupDeletions {
      oldId
    }
  }
`

export const billingDashboardSummary = gql`
  fragment BillingDashboardSummaryProperties on BillingDashboardSummary {
    projectsBilledThisMonth
    totalProjectsThisMonth
    projectsBilledMonthOverMonthPercent
    payAppStatusBreakdown {
      pastDue
      notBillingThisMonth
      draftOnTime
      submitted
    }
    amountBilledAndDraftThisMonth
    amountBilledAndDraftMonthOverMonthPercent
    amountBilledThisMonth
    amountBilledMonthOverMonthPercent
    amountInDraftThisMonth
    amountInDraftMonthOverMonthPercent
    retentionHeldThisMonth
    retentionHeldThruMonth
    retentionHeldMonthOverMonthPercent
    progressBilledMinusRetentionHeldThisMonth
    progressBilledMinusRetentionHeldMonthOverMonthPercent
    progressBilledAndDraftThisMonth
    progressBilledAndDraftMonthOverMonthPercent
    retentionBilledThisMonth
    retentionBilledMonthOverMonthPercent
    retentionBilledAndDraftThisMonth
    retentionBilledAndDraftMonthOverMonthPercent
    progressBilledAndDraftMinusRetentionHeldThisMonth
    progressBilledAndDraftMinusRetentionHeldMonthOverMonthPercent
    netAmountBilledAndDraftThisMonth
    netAmountBilledAndDraftMonthOverMonthPercent
    netAmountInDraftThisMonth
    netAmountInDraftMonthOverMonthPercent
    monthlyBillingBreakdown {
      month
      amountBilledAndDraftThisMonth
      amountBilledThisMonth
    }
  }
`

export const projectMetrics = gql`
  fragment ProjectMetricsProperties on ProjectMetrics {
    amountBilledAndDraftThisMonth
    netAmountBilledAndDraftThisMonth
    retentionHeldThisMonth
    retentionHeldThruMonth
    percentCompletedThruMonth
    balanceToFinish
    completedThruMonth
    netCompletedThruMonth
    totalValue
  }
`

export const lineItemMetrics = gql`
  fragment LineItemMetricsProperties on LineItemMetrics {
    number
    name
    costCode
    amountBilledAndDraftThisMonth
    percentCompletedThruMonth
    balanceToFinish
    completedThruMonth
    totalValue
  }
`

export const contractForBillingDashboard = gql`
  fragment ContractForBillingDashboardProperties on Contract {
    id
    billingType
    internalProjectNumber
    sov {
      id
      lineItems {
        id
        costCode
      }
    }
    project {
      id
      name
      projectNumber
      generalContractor {
        companyName
        company {
          id
          name
        }
      }
    }
    leadPMs {
      id
      firstName
      lastName
    }
  }
`

export const billingDashboardContract = gql`
  fragment BillingDashboardContractProperties on BillingDashboardContract {
    contract {
      ...ContractForBillingDashboardProperties
    }
    projectMetrics {
      ...ProjectMetricsProperties
    }
    billingStatus
  }
  ${contractForBillingDashboard}
  ${projectMetrics}
`

export const billingDashboardContractWithLineItems = gql`
  fragment BillingDashboardContractWithLineItemsProperties on BillingDashboardContractWithLineItems {
    contract {
      ...ContractForBillingDashboardProperties
    }
    projectMetrics {
      ...ProjectMetricsProperties
    }
    lineItems {
      ...LineItemMetricsProperties
    }
    billingStatus
  }
  ${contractForBillingDashboard}
  ${projectMetrics}
  ${lineItemMetrics}
`

export const billingDashboard = gql`
  fragment BillingDashboardProperties on BillingDashboard {
    billingSummary {
      ...BillingDashboardSummaryProperties
    }
    contracts {
      ...BillingDashboardContractProperties
    }
  }
  ${billingDashboardSummary}
  ${billingDashboardContract}
`

export const costCodeReport = gql`
  fragment CostCodeReportProperties on CostCodeReport {
    costCodeSummary {
      ...BillingDashboardSummaryProperties
    }
    billedByCostCodeAndJobThisMonth {
      grossBilling
      netBilling
      retentionHeld
      contract {
        id
        internalProjectNumber
        project {
          id
          name
          projectNumber
        }
      }
      costCode
    }
    billedByCostCodeAndJobAllTime {
      grossBilling
      netBilling
      retentionHeld
      contract {
        id
        internalProjectNumber
        project {
          id
          name
          projectNumber
        }
      }
      costCode
    }
    billedPerCostCode {
      costCode
      scheduledValue
      grossBilledThisMonth
      netBilledThisMonth
      retentionHeldThisMonth
      totalGrossBilled
      totalNetBilled
      balanceToFinish
      retentionHeldAllTime
    }
  }
  ${billingDashboardSummary}
`

export const agingBreakdown = gql`
  fragment AgingBreakdownProperties on AgingBreakdown {
    numCurrent
    numAged30Days
    numAged60Days
    numAged90Days
    numAged120Days
    amountAgedTotal
    amountAgedCurrent
    amountAged30Days
    amountAged60Days
    amountAged90Days
    amountAged120Days
    amountAgedTotalOverdueOnly
  }
`

export const payAppAgingDashboardSummary = gql`
  fragment PayAppAgingDashboardSummaryProperties on PayAppAgingDashboardSummary {
    amountOutstandingThisMonth
    amountOutstandingMonthOverMonthPercent
    amountAged30Days
    amountAged30DaysMonthOverMonthPercent
    amountAged60Days
    amountAged60DaysMonthOverMonthPercent
    amountAged90Days
    amountAged90DaysMonthOverMonthPercent
    amountAged120Days
    amountAged120DaysMonthOverMonthPercent
    averageDaysToPaid
    averageDaysToPaidMonthOverMonthPercent

    payAppAgingBreakdown {
      ...AgingBreakdownProperties
    }
  }
  ${agingBreakdown}
`

export const payAppAgingDashboardContract = gql`
  fragment PayAppAgingDashboardContractProperties on PayAppAgingDashboardContract {
    contract {
      id
      billingType
      internalProjectNumber
      paymentTermsType
      paymentTerms
      project {
        id
        name
        projectNumber
        generalContractor {
          companyName
          company {
            id
            name
          }
        }
      }
      company {
        id
      }
      leadPMs {
        id
        firstName
        lastName
      }
    }
    agingBreakdown {
      ...AgingBreakdownProperties
    }
    billingStatus
    hasMissingPreSitelinePayApps
  }
  ${agingBreakdown}
`

export const agingDashboard = gql`
  fragment AgingDashboardProperties on PayAppAgingDashboard {
    payAppAgingSummary {
      ...PayAppAgingDashboardSummaryProperties
    }
    contracts {
      ...PayAppAgingDashboardContractProperties
    }
  }
  ${payAppAgingDashboardSummary}
  ${payAppAgingDashboardContract}
`

export const vendorLienWaiverDashboardSummary = gql`
  fragment VendorLienWaiverDashboardSummaryProperties on VendorLienWaiverDashboardSummary {
    numOutstandingLienWaiversThisMonth
    outstandingVendors
    outstandingLienWaiversByMonth {
      month
      numOutstandingLienWaiversThisMonth
    }
  }
`

export const overviewDashboard = gql`
  fragment OverviewDashboardProperties on OverviewDashboard {
    billingSummary {
      ...BillingDashboardSummaryProperties
    }
    payAppAgingSummary {
      ...PayAppAgingDashboardSummaryProperties
    }
    vendorSummary {
      ...VendorLienWaiverDashboardSummaryProperties
    }
  }
  ${billingDashboardSummary}
  ${payAppAgingDashboardSummary}
  ${vendorLienWaiverDashboardSummary}
`

export const contractOptedOutMonth = gql`
  fragment ContractOptedOutMonthProperties on ContractOptedOutMonth {
    id
    createdAt
    month
    reason
    user {
      id
      firstName
      lastName
    }
  }
`

export const contractForVendorsProjectHome = gql`
  fragment ContractForVendorsProjectHome on Contract {
    ...MinimalContractProperties
    missingRequiredVendorLienWaiverFields
    vendorComplianceStatus
    vendorsInternalNotes
    company {
      id
    }
    legalRequirements {
      ...MinimalLegalRequirementProperties
      vendorTemplate {
        id
        name
        url
      }
    }
    defaultGcContacts {
      ...CompanyContactProperties
    }
    legalRequirementContacts {
      ...CompanyContactProperties
    }
    vendorSubmitToContacts {
      ...CompanyContactProperties
    }
    onboardedStatus {
      ...OnboardedProjectContractStatusProperties
    }
    onboardedProjectVendorsStatus {
      ...OnboardedProjectVendorsStatusProperties
    }
    project {
      id
      name
      projectNumber
      bondNumber
      timeZone
      location {
        ...LocationProperties
      }
      generalContractor {
        companyName
        company {
          id
          name
        }
        selectedAddress {
          ...LocationProperties
        }
      }
      owner {
        companyName
        company {
          id
          name
        }
        selectedAddress {
          ...LocationProperties
        }
      }
      architect {
        companyName
        company {
          id
          name
        }
        selectedAddress {
          ...LocationProperties
        }
      }
      bondProvider {
        companyName
        company {
          id
          name
        }
        selectedAddress {
          ...LocationProperties
        }
      }
      metadata {
        payAppDueOnDayOfMonth
      }
    }
    timeZone
    payApps {
      id
      billingEnd
    }
    preSitelinePayApps {
      id
      billingEnd
      payAppNumber
    }
    integrations {
      ...MinimalIntegrationProperties
    }
    lowerTierLienWaiverTemplates {
      ...MinimalSubcontractorLienWaiverTemplateSetProperties
    }
    vendorContracts {
      ...VendorContractProperties
    }
  }
  ${companyContact}
  ${minimalLegalRequirement}
  ${onboardedProjectContractStatus}
  ${minimalIntegration}
  ${location}
  ${minimalSubcontractorLienWaiverTemplateSet}
  ${onboardedProjectVendorsStatus}
  ${vendorContract}
`

export const preSitelinePayAppForProjectHome = gql`
  fragment PreSitelinePayAppForProjectHomeProperties on PreSitelinePayApp {
    id
    createdAt
    timeZone
    retentionOnly
    payAppNumber
    billingStart
    billingEnd
    status
    payAppFile {
      ...StoredFileProperties
    }
    currentBilled
    paymentDue
    percentComplete
    isFinal
    paidAt
    amountPaid
    isBalanceManuallyClosed
    submittedUnconditionalOutsideOfSiteline
    submittedAt
    isNotarizingUnconditional
    unconditionalLienWaiver {
      id
    }
    contract {
      id
      averageDaysToPaid
    }
  }
  ${storedFile}
`

export const contractBillingForecast = gql`
  fragment ContractBillingForecastProperties on ContractBillingForecast {
    id
    startingMonth
    firstEditableMonthWhenCreated
    projectedAmountPerMonth
    remainingAmount
    curve
    lastEditedBy {
      id
      firstName
      lastName
    }
  }
`

export const aggregateForecastMonth = gql`
  fragment AggregateForecastMonthProperties on AggregateForecastMonth {
    month
    year
    projectedAmount
    amountBilled
  }
`

export const contractCost = gql`
  fragment ContractCostProperties on ContractCost {
    id
    updatedAt
    updatedFromIntegrationType
    costToDate
    totalEstimatedCost
    additionalCommittedCost
    costPercentageCompleted
    earnedRevenue
    contractTotalValue
    contractTotalBilled
    billingsInExcessOfCost
    manuallyUpdatedBy {
      id
      firstName
      lastName
    }
  }
`

export const contractForCashForecast = gql`
  fragment ContractForCashForecastProperties on Contract {
    id
    billingType
    internalProjectNumber
    isJointCheck
    invoiceAmountOutstanding
    outstandingVendorLienWaiverAmount
    timeZone
    leadPMs {
      id
    }
    selectedAddress {
      id
    }
    project {
      id
      name
      projectNumber
      generalContractor {
        companyName
        company {
          id
          name
        }
      }
    }
    payApps {
      id
      payAppNumber
      predictedPaymentDate
      currentBilled
      currentRetention
      previousRetentionBilled
      amountDuePostTax
      isBalanceManuallyClosed
      amountPaid
      status
      timeZone
      retentionOnly
      billingEnd
    }
    preSitelinePayApps {
      id
      payAppNumber
      status
      paymentDue
      retentionOnly
      billingEnd
      amountPaid
      isBalanceManuallyClosed
    }
  }
`

export const payAppForOverview = gql`
  fragment PayAppForOverviewProperties on PayApp {
    ...PayAppProperties
    balanceToFinish
    automaticallyReadBilling
    retentionHeldPercent
    retentionHeldOverride
    retentionReleased
    amountDuePreTax
    amountDuePostTax
    amountDueTaxAmount
    lastSubmitted {
      id
      status
      statusUpdatedAt
    }
    revertToDraftEvents {
      id
      createdAt
      createdBy {
        id
        firstName
        lastName
      }
      metadata
    }
    integrationPaidAt
    canGenerateForms
    missingRequiredFields
    predictedPaymentDate
    hasContractRateTableChanged
    haveContractFeesChanged
    haveContractTaxesChanged
    attachments {
      ...PayAppAttachmentProperties
    }
    formTemplates {
      formTemplateVariant {
        id
        template {
          id
        }
      }
    }
    lienWaivers {
      ...LienWaiverProperties
      lienWaiverRequests {
        ...LienWaiverRequestProperties
      }
    }
    legalDocuments {
      ...MinimalLegalDocumentProperties
      legalRequirement {
        ...MinimalLegalRequirementProperties
      }
    }
    rateTable {
      ...MinimalRateTableProperties
    }
  }
  ${lienWaiverRequest}
  ${lienWaiver}
  ${payApp}
  ${payAppAttachment}
  ${minimalLegalDocument}
  ${minimalLegalRequirement}
  ${minimalRateTable}
`

export const contractForChangeOrderReport = gql`
  fragment ContractForChangeOrderReportProperties on Contract {
    id
    internalProjectNumber
    timeZone
    leadPMs {
      id
      firstName
      lastName
    }
    project {
      id
      projectNumber
      name
      generalContractor {
        companyName
        company {
          id
          name
        }
      }
    }
  }
`

export const aggregateChangeOrderData = gql`
  fragment AggregateChangeOrderDataProperties on AggregateChangeOrderData {
    totalAmountUnapproved
    totalAmountAuthorizedToProceed
    totalAmountProceedingWithWork
    totalAmountApproved
    totalOriginalContractAmount
    totalRevisedContractAmount
  }
`

export const changeOrderSummaryReport = gql`
  fragment ChangeOrderSummaryReportProperties on ChangeOrderSummaryReport {
    aggregateChangeOrderTotals {
      ...AggregateChangeOrderDataProperties
    }
    aggregateChangeOrderDataByContract {
      aggregateChangeOrderData {
        ...AggregateChangeOrderDataProperties
      }
      contract {
        ...ContractForChangeOrderReportProperties
      }
    }
  }
  ${aggregateChangeOrderData}
  ${contractForChangeOrderReport}
`

export const pendingChangeOrderReport = gql`
  fragment PendingChangeOrderReportProperties on PendingChangeOrderReport {
    totalAmountRequested
    totalAmountInDraft
    totalAmountApproved
    totalOriginalContractAmount
    totalRevisedContractAmount
    totalAmountAuthorizedToProceed
    totalAmountProceedingWithWork
    changeOrderRequests {
      ...MinimalChangeOrderRequestProperties
      contract {
        ...ContractForChangeOrderReportProperties
      }
    }
  }
  ${minimalChangeOrderRequest}
  ${contractForChangeOrderReport}
`

export const lienWaiverRequestForLowerTierSignature = gql`
  fragment LienWaiverRequestForLowerTierSignatureProperties on LienWaiverRequest {
    id
    status
    vendorContact {
      ...VendorContactProperties
      defaultSignature {
        ...SignatureProperties
      }
    }
    requestedBy {
      id
      firstName
      lastName
    }
    lienWaiver {
      id
      type
      timeZone
      amount
      date
      claimant
      message
      payApps {
        id
        emailedContacts {
          id
          companyName
        }
      }
      contract {
        id
        internalProjectNumber
        company {
          id
          name
        }
        project {
          id
          name
          projectNumber
        }
      }
      formValues {
        ...FormTemplateAnnotationValueProperties
      }
      requestAttachments {
        ...StoredFileProperties
      }
      vendorContract {
        id
        lowerTierTo {
          id
          vendor {
            id
            name
          }
        }
      }
    }
  }
  ${lienWaiverRequest}
  ${formTemplateAnnotation}
  ${storedFile}
`

export const collectionsTask = gql`
  fragment CollectionsTaskProperties on CollectionsTask {
    id
    createdAt
    updatedAt
    description
    dueDate
    status
    payApp {
      id
      payAppNumber
      collectionsTasks {
        id
      }
      contract {
        id
        internalProjectNumber
        project {
          id
          projectNumber
          name
          timeZone
        }
      }
    }
    createdBy {
      id
      firstName
      lastName
    }
  }
`

export const contractCollectionsNotification = gql`
  fragment ContractCollectionsNotificationProperties on ContractCollectionsNotification {
    id
    createdAt
    updatedAt
    archivedAt
    type
    intervalAmount
    intervalGranularity
    intervalModifier
    intervalAnchor
    subjectTemplate
    bodyTemplate
    sender {
      ...MinimalUserProperties
    }
    gcContacts {
      ...CompanyContactProperties
    }
    internalCcRecipients {
      ...MinimalUserProperties
    }
    contract {
      id
      collectionsNotifications {
        id
      }
      payApps {
        id
        collectionsNotifications {
          id
        }
      }
    }
  }
  ${minimalUser}
  ${companyContact}
`

export const payAppCollectionsNotification = gql`
  fragment PayAppCollectionsNotificationProperties on PayAppCollectionsNotification {
    id
    overrideSendTime
    sendTime
    lastSentOn
    canceledAt
    status
    sendLogs {
      id
      sendTime
      sender {
        ...MinimalUserProperties
      }
      gcContacts {
        ...CompanyContactProperties
      }
      notification {
        id
      }
    }
    contractNotification {
      id
      ...ContractCollectionsNotificationProperties
    }
    payApp {
      id
      payAppNumber
    }
  }
  ${minimalUser}
  ${companyContact}
  ${contractCollectionsNotification}
`

export const billedPerCostCode = gql`
  fragment BilledPerCostCodeProperties on BilledPerCostCode {
    costCode
    scheduledValue
    grossBilledThisMonth
    netBilledThisMonth
    retentionHeldThisMonth
    balanceToFinish
    totalGrossBilled
    totalNetBilled
    retentionHeldAllTime
  }
`

export const contractBilledPerTaxGroup = gql`
  fragment ContractBilledPerTaxGroupProperties on ContractBilledPerTaxGroup {
    taxGroup {
      id
      name
      taxPercent
    }
    contract {
      id
      internalProjectNumber
      project {
        id
        name
        projectNumber
      }
    }
    taxAmountThisPeriod
    scheduledValue
    state
  }
`

export const billedPerTaxGroup = gql`
  fragment BilledPerTaxGroupProperties on BilledPerTaxGroup {
    taxGroup {
      id
      name
      taxPercent
    }
    taxAmountThisPeriod
    scheduledValue
  }
`

export const taxGroupReportSummary = gql`
  fragment TaxGroupReportSummaryProperties on TaxGroupReportSummary {
    totalTaxAmountThisPeriod
    numProjects
  }
`

export const taxesByState = gql`
  fragment TaxesByStateProperties on TaxesByState {
    state
    totalScheduledValue
    totalTaxAmountThisPeriod
  }
`

export const taxGroupReport = gql`
  fragment TaxGroupReportProperties on TaxGroupReport {
    contractBilledPerTaxGroup {
      ...ContractBilledPerTaxGroupProperties
    }
    billedPerTaxGroup {
      ...BilledPerTaxGroupProperties
    }
    summary {
      ...TaxGroupReportSummaryProperties
    }
    taxesByState {
      ...TaxesByStateProperties
    }
  }
  ${contractBilledPerTaxGroup}
  ${billedPerTaxGroup}
  ${taxGroupReportSummary}
  ${taxesByState}
`

export const integrationProjectsFilter = gql`
  fragment IntegrationProjectsFilterProperties on IntegrationProjectsFilter {
    field
    options {
      value
      label
    }
    multiple
    defaultValue
    showAllOption
  }
`

export const vendorInvoiceImportOperation = gql`
  fragment VendorInvoiceImportOperationProperties on VendorInvoiceImportOperation {
    id
    createdAt
    startDate
    endDate
    status
    errors
    invoiceCount
    contract {
      id
    }
    company {
      ...CompanyProperties
    }
    companyIntegration {
      ...CompanyIntegrationProperties
    }
  }
  ${company}
  ${companyIntegration}
`
