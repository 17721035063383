import { gql } from '@apollo/client'
import DeleteIcon from '@mui/icons-material/Delete'
import SettingsIcon from '@mui/icons-material/Settings'
import {
  Autocomplete,
  IconButton,
  MenuItem,
  Select,
  Skeleton,
  Switch,
  TextField,
  Tooltip,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  BillingType,
  CompanyIntegrationMetadataFoundation,
  IntegrationTypeFamily,
  QUICKBOOKS_CUSTOMER_MAX_CHAR_COUNT,
  QUICKBOOKS_DEFAULT_COMBINE_AS_SINGLE_LINE,
  QUICKBOOKS_DEFAULT_INCLUDE_RETENTION,
  QUICKBOOKS_DEFAULT_SINGLE_LINE_ITEM_DESCRIPTION,
  QUICKBOOKS_JOB_MAX_CHAR_COUNT,
  TAX_RATE_PERCENT_PRECISION,
  decimalToPercent,
  getIntegrationCustomer,
  getIntegrationTypeFamily,
  integrationTypes,
  supportsProjectOnboarding,
  supportsReadingPayments,
  supportsReadingTaxGroups,
  supportsSettingCustomer,
  supportsTaxGroupInIntegrationMappings,
} from 'siteline-common-all'
import {
  IntegrationMappings,
  IntegrationMappingsSage300Cre,
  IntegrationMappingsViewpointSpectrum,
  QuickbooksIntegrationMetadata,
} from 'siteline-common-all/src/types/integration'
import {
  IntegrationGeneralLedgerAccount,
  IntegrationIncomeTypeNumber,
  Permission,
  Row,
  SitelineText,
  Spacer,
  colors,
  makeStylesFast,
  toReferences,
  useSitelineSnackbar,
  useToggle,
} from 'siteline-common-web'
import { SitelineAlert } from '../../../common/components/SitelineAlert'
import { useSitelineConfirmation } from '../../../common/components/SitelineConfirmation'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import * as fragments from '../../../common/graphql/Fragments'
import {
  Integration,
  IntegrationCustomerProperties,
  IntegrationProjectSummaryProperties,
  IntegrationTaxGroupProperties,
  IntegrationType,
  MinimalIntegrationProperties,
  useAddIntegrationToContractMutation,
  useCompanyForCreateProjectDialogQuery,
  useCompanyIntegrationTaxGroupsLazyQuery,
  useDeleteIntegrationMutation,
  useGetIntegrationGeneralLedgerAccountsLazyQuery,
  useGetIntegrationIncomeTypeNumbersLazyQuery,
  useIntegrationCustomersLazyQuery,
  useSetIntegrationCustomerMutation,
  useSetIntegrationTaxGroupMutation,
  useUpdateContractMutation,
  useUpdateIntegrationMappingsMutation,
  useUpdateIntegrationMetadataMutation,
} from '../../../common/graphql/apollo-operations'
import {
  getIntegrationOfFamily,
  useIntegrationProjectsForOnboarding,
} from '../../../common/util/Integration'
import { getGeneralContractorName } from '../../../common/util/Project'
import { ContractForProjectHome } from '../home/ProjectHome'
import {} from '../invoice/export/ExportDataDialog'
import { CompanyIntegrationForCreateProject } from '../onboarding/CreateProjectDialog'
import { LinkIntegrationProject } from '../onboarding/LinkIntegrationProject'
import { getIncomeTypeLabel, getLedgerAccountLabel } from '../sync/SyncFoundationDialog'
import { initialFoundationValues } from './FoundationSettings'
import { IntegrationSettings } from './IntegrationSettings'
import {
  QUICKBOOKS_SETTINGS_LABEL_WIDTH,
  QuickbooksProjectMetadata,
  QuickbooksProjectSettings,
} from './QuickbooksProjectSettings'
import { SettingsHeader } from './SettingsHeader'
import { SettingsRow } from './SettingsRow'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(1),
  },
  divider: {
    width: '100%',
    backgroundColor: colors.grey20,
    height: 1,
    marginBottom: theme.spacing(3),
  },
  editing: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(-1, 0),
    '& .select': {
      marginRight: theme.spacing(2),
      width: 300,
    },
    '& .editInSettings': {
      marginLeft: theme.spacing(1),
    },
  },
  integration: {
    '& > div': {
      minWidth: 500,
    },
  },
  integrationSelect: {
    marginTop: theme.spacing(-2),
  },
  projectSelect: {
    marginTop: theme.spacing(1),
  },
  gcLinkWarning: {
    marginTop: theme.spacing(1),
  },
  integrationIcon: {
    margin: theme.spacing(-1, 0),
  },
  quickbooks: {
    marginBottom: theme.spacing(2),
  },
  switch: {
    // Aligns the <Switch> with the rest of the text in the card
    margin: theme.spacing(-1, -1.5),
  },
}))

gql`
  mutation addIntegrationToContract($input: AddIntegrationToContractInput!) {
    addIntegrationToContract(input: $input) {
      ...MinimalContractProperties
    }
  }
  ${fragments.minimalContract}
`

gql`
  mutation setIntegrationTaxGroup($input: SetIntegrationTaxGroupInput!) {
    setIntegrationTaxGroup(input: $input) {
      ...MinimalIntegrationProperties
    }
  }
  ${fragments.minimalIntegration}
`

gql`
  query integrationCustomers($input: GetIntegrationCustomersInput!) {
    integrationCustomers(input: $input) {
      ...IntegrationCustomerProperties
    }
  }
  ${fragments.integrationCustomer}
`

gql`
  mutation setIntegrationCustomer($input: SetIntegrationCustomerInput!) {
    setIntegrationCustomer(input: $input) {
      ...MinimalIntegrationProperties
    }
  }
  ${fragments.minimalIntegration}
`
gql`
  mutation updateIntegrationMappings($input: UpdateIntegrationMappingsInput!) {
    updateIntegrationMappings(input: $input) {
      ...MinimalIntegrationProperties
    }
  }
  ${fragments.minimalIntegration}
`

gql`
  mutation deleteIntegration($id: ID!) {
    deleteIntegration(id: $id) {
      id
    }
  }
`

const i18nBase = 'projects.subcontractors.settings.integrations'

interface IntegrationsProps {
  contract?: ContractForProjectHome
}

function renderTaxGroup(taxGroup: { name: string; taxPercent: number }) {
  const taxPercent = decimalToPercent(taxGroup.taxPercent, TAX_RATE_PERCENT_PRECISION)
  return `${taxGroup.name} (${taxPercent}%)`
}

type TaxGroupIntegrationMappings = Extract<
  IntegrationMappings,
  IntegrationMappingsSage300Cre | IntegrationMappingsViewpointSpectrum
>

/** Shows project info fields and allows you to edit them. */
export function Integrations({ contract }: IntegrationsProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()
  const { confirm } = useSitelineConfirmation()

  const { isContractActive } = useProjectContext()
  const { permissions } = useCompanyContext()
  const { companyId } = useCompanyContext()
  const canEdit = permissions.includes(Permission.EDIT_PROJECT_SETTINGS) && isContractActive
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)

  const initialEnableAutoMarkAsPaidFromIntegrations =
    contract?.enableAutoMarkAsPaidFromIntegrations ?? true
  const [enableAutoMarkAsPaidFromIntegrations, setEnableAutoMarkAsPaidFromIntegrations] =
    useState<boolean>(initialEnableAutoMarkAsPaidFromIntegrations)

  const [updateContract] = useUpdateContractMutation()
  const [addIntegrationToContract] = useAddIntegrationToContractMutation()
  const [deleteIntegration] = useDeleteIntegrationMutation()
  const { data: companyData } = useCompanyForCreateProjectDialogQuery({
    variables: { id: companyId },
  })
  const [fetchErpTaxGroups, { data: integrationGroupsData }] =
    useCompanyIntegrationTaxGroupsLazyQuery()
  const [fetchCustomers, { data: integrationCustomersData }] = useIntegrationCustomersLazyQuery()
  const [fetchGeneralLedgerAccounts, { data: integrationGeneralLedgerAccountsData }] =
    useGetIntegrationGeneralLedgerAccountsLazyQuery()

  const [fetchIncomeTypes, { data: integrationIncomeTypesData }] =
    useGetIntegrationIncomeTypeNumbersLazyQuery()

  const [fetchErpProjects, erpProjects, fetchingErpProjects, erpProjectsError] =
    useIntegrationProjectsForOnboarding()
  const [fetchGcPortalProjects, gcPortalProjects, fetchingGcPortalProjects, gcPortalProjectsError] =
    useIntegrationProjectsForOnboarding()

  const [updateIntegrationMetadata] = useUpdateIntegrationMetadataMutation()
  const [updateIntegrationMappings] = useUpdateIntegrationMappingsMutation()

  const [hasEdited, setHasEdited] = useState<boolean>(false)
  const [selectedErpProject, setSelectedErpProject] =
    useState<IntegrationProjectSummaryProperties | null>(null)
  const [pendingErpIntegration, setPendingErpIntegration] =
    useState<CompanyIntegrationForCreateProject | null>(null)

  const [selectedGcPortalProject, setSelectedGcPortalProject] =
    useState<IntegrationProjectSummaryProperties | null>(null)
  const [pendingGcPortalIntegration, setPendingGcPortalIntegration] =
    useState<CompanyIntegrationForCreateProject | null>(null)

  const erpIntegration = contract
    ? getIntegrationOfFamily(contract, IntegrationTypeFamily.ERP)
    : null

  const [setIntegrationTaxGroup] = useSetIntegrationTaxGroupMutation({
    refetchQueries: ['integrationTaxGroups'],
  })
  const [setIntegrationCustomer] = useSetIntegrationCustomerMutation({
    refetchQueries: ['integrationCustomers'],
  })

  const integrationGroups = useMemo(
    () =>
      _.orderBy(
        [...(integrationGroupsData?.companyIntegrationTaxGroups ?? [])],
        (taxGroup) => taxGroup.name,
        'asc'
      ),
    [integrationGroupsData]
  )
  const shouldShowTaxGroups = useMemo(
    () =>
      erpIntegration !== null &&
      supportsReadingTaxGroups(erpIntegration.type) &&
      supportsTaxGroupInIntegrationMappings(erpIntegration.type),
    [erpIntegration]
  )
  const currentErpTaxGroup = useMemo(() => {
    if (!shouldShowTaxGroups || !erpIntegration) {
      return null
    }
    return (erpIntegration.mappings as TaxGroupIntegrationMappings).taxGroup ?? null
  }, [shouldShowTaxGroups, erpIntegration])
  const initialErpTaxGroup = useMemo(() => {
    if (!erpIntegration) {
      return null
    }
    let currentTaxGroupId: string | undefined = undefined
    switch (erpIntegration.type) {
      case IntegrationType.SAGE_300_CRE:
        currentTaxGroupId = (
          erpIntegration.mappings as integrationTypes.IntegrationMappingsSage300Cre
        ).taxGroup?.hh2TaxGroupId
        break
      case IntegrationType.SPECTRUM:
        currentTaxGroupId = (
          erpIntegration.mappings as integrationTypes.IntegrationMappingsViewpointSpectrum
        ).taxGroup?.agaveTaxGroupId
        break
      case IntegrationType.SAGE_300_CRE_AGAVE:
        currentTaxGroupId = (
          erpIntegration.mappings as integrationTypes.IntegrationMappingsSage300CreAgave
        ).taxGroup?.agaveTaxGroupId
        break
      case IntegrationType.ACUMATICA:
      case IntegrationType.COMPUTER_EASE_FILE:
      case IntegrationType.FOUNDATION:
      case IntegrationType.FOUNDATION_FILE:
      case IntegrationType.GC_PAY:
      case IntegrationType.PROCORE:
      case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
      case IntegrationType.SAGE_100_CONTRACTOR:
      case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
      case IntegrationType.TEST:
      case IntegrationType.TEXTURA:
      case IntegrationType.CMIC:
      case IntegrationType.SAGE_INTACCT:
      case IntegrationType.VISTA:
        currentTaxGroupId = undefined
        break
    }
    return (
      integrationGroups.find((taxGroup) => taxGroup.integrationTaxGroupId === currentTaxGroupId) ??
      null
    )
  }, [erpIntegration, integrationGroups])
  const [selectedErpTaxGroup, setSelectedErpTaxGroup] =
    useState<IntegrationTaxGroupProperties | null>(initialErpTaxGroup)

  const integrationCustomers = useMemo(
    () =>
      _.orderBy(
        [...(integrationCustomersData?.integrationCustomers ?? [])],
        (customer) => customer.name,
        'asc'
      ),
    [integrationCustomersData]
  )
  const integrationGeneralLedgerAccounts = useMemo(
    () =>
      _.orderBy(
        integrationGeneralLedgerAccountsData?.integrationGeneralLedgerAccounts ?? [],
        (account) => account.name,
        'asc'
      ),
    [integrationGeneralLedgerAccountsData]
  )
  const ledgerAccountMappings = useMemo(() => {
    if (!erpIntegration || erpIntegration.type !== IntegrationType.FOUNDATION) {
      return null
    }
    const integrationMappings =
      erpIntegration.mappings as integrationTypes.IntegrationMappingsFoundation
    const companyIntegrationMetadata = erpIntegration.companyIntegration
      .metadata as CompanyIntegrationMetadataFoundation

    return integrationMappings.ledgerAccount ?? companyIntegrationMetadata.ledgerAccount
  }, [erpIntegration])

  const retentionLedgerAccountMappings = useMemo(() => {
    if (!erpIntegration || erpIntegration.type !== IntegrationType.FOUNDATION) {
      return null
    }
    const integrationMappings =
      erpIntegration.mappings as integrationTypes.IntegrationMappingsFoundation
    const companyIntegrationMetadata = erpIntegration.companyIntegration
      .metadata as CompanyIntegrationMetadataFoundation

    return (
      integrationMappings.retentionLedgerAccount ??
      companyIntegrationMetadata.retentionLedgerAccount
    )
  }, [erpIntegration])

  // I added name to the fields that are saved along with the ledger account id
  // but most projects did not store the name initially.
  // We can update the name every time someone changes the default GL account
  // rather than running a migration to call Agave for each project and update the name.
  // This memo gets the name from the stored mappings if it exists;
  // later, we get the name from Agave if this name doesn't exist
  const storedIntegrationGeneralLedgerAccountName = useMemo(() => {
    return ledgerAccountMappings ? ledgerAccountMappings.name : null
  }, [ledgerAccountMappings])

  const storedIntegrationRetentionGeneralLedgerAccountName = useMemo(() => {
    return retentionLedgerAccountMappings ? retentionLedgerAccountMappings.name : null
  }, [retentionLedgerAccountMappings])

  const integrationIncomeTypes = useMemo(
    () =>
      _.orderBy(
        [...(integrationIncomeTypesData?.integrationIncomeTypeNumbers ?? [])],
        (incomeType) => incomeType.incomeTypeNumber,
        'asc'
      ),
    [integrationIncomeTypesData]
  )
  // Not all Foundation customers use income types
  // See https://github.com/siteline/siteline/pull/16020
  const isIncomeTypeEnabled = integrationIncomeTypes.length > 0

  const shouldShowCustomerInput = useMemo(
    () => (erpIntegration ? supportsSettingCustomer(erpIntegration.type) : false),
    [erpIntegration]
  )
  const currentErpCustomer = useMemo(() => {
    if (!shouldShowCustomerInput || !erpIntegration) {
      return null
    }
    return getIntegrationCustomer(erpIntegration)
  }, [erpIntegration, shouldShowCustomerInput])
  const initialErpCustomer = useMemo(
    () =>
      integrationCustomers.find(
        (taxGroup) => taxGroup.integrationCustomerId === currentErpCustomer?.id
      ) ?? null,
    [integrationCustomers, currentErpCustomer]
  )
  const [selectedErpCustomer, setSelectedErpCustomer] =
    useState<IntegrationCustomerProperties | null>(initialErpCustomer)

  const {
    glAccount: initialErpDefaultGlAccount,
    retentionGlAccount: initialErpDefaultRetentionGlAccount,
    incomeType: initialErpIncomeType,
  } = useMemo(() => {
    if (!erpIntegration || erpIntegration.type !== IntegrationType.FOUNDATION) {
      return { glAccount: null, retentionGlAccount: null, incomeType: null }
    }
    return initialFoundationValues({
      erpIntegration,
      integrationIncomeTypes,
      integrationGeneralLedgerAccounts,
    })
  }, [erpIntegration, integrationIncomeTypes, integrationGeneralLedgerAccounts])

  const [selectedErpDefaultRetentionGlAccount, setSelectedErpDefaultRetentionGlAccount] =
    useState<IntegrationGeneralLedgerAccount | null>(initialErpDefaultRetentionGlAccount)
  const [selectedErpDefaultGlAccount, setSelectedErpDefaultGlAccount] =
    useState<IntegrationGeneralLedgerAccount | null>(initialErpDefaultGlAccount)

  const integrationGeneralLedgerAccountCode = useMemo(() => {
    if (selectedErpDefaultGlAccount) {
      return selectedErpDefaultGlAccount.code
    }
    if (ledgerAccountMappings) {
      return ledgerAccountMappings.code
    }
    return null
  }, [ledgerAccountMappings, selectedErpDefaultGlAccount])

  const integrationRetentionGeneralLedgerAccountName = useMemo(() => {
    if (selectedErpDefaultRetentionGlAccount) {
      return selectedErpDefaultRetentionGlAccount.name
    }
    if (retentionLedgerAccountMappings) {
      if (retentionLedgerAccountMappings.name) {
        return retentionLedgerAccountMappings.name
      }
      return retentionLedgerAccountMappings.agaveSourceId
    }
    return null
  }, [retentionLedgerAccountMappings, selectedErpDefaultRetentionGlAccount])

  const integrationRetentionGeneralLedgerAccountCode = useMemo(() => {
    if (selectedErpDefaultRetentionGlAccount) {
      return selectedErpDefaultRetentionGlAccount.code
    }
    if (retentionLedgerAccountMappings) {
      return retentionLedgerAccountMappings.agaveSourceId
    }
    return null
  }, [retentionLedgerAccountMappings, selectedErpDefaultRetentionGlAccount])

  const [selectedErpIncomeType, setSelectedErpIncomeType] =
    useState<IntegrationIncomeTypeNumber | null>(initialErpIncomeType ?? null)
  const incomeTypeName = useMemo(() => {
    const returnedIncomeType = integrationIncomeTypes.find(
      (incomeType) => incomeType.incomeTypeNumber === selectedErpIncomeType?.incomeTypeNumber
    )
    if (returnedIncomeType) {
      return getIncomeTypeLabel(returnedIncomeType)
    }
    return null
  }, [selectedErpIncomeType, integrationIncomeTypes])

  const initialQuickbooksMetadata = useMemo(() => {
    if (!erpIntegration || erpIntegration.type !== IntegrationType.QUICKBOOKS_ENTERPRISE_FILE) {
      return null
    }
    return erpIntegration.metadata as QuickbooksIntegrationMetadata
  }, [erpIntegration])
  const [quickbooksMetadata, setQuickbooksMetadata] = useState<QuickbooksProjectMetadata | null>(
    initialQuickbooksMetadata
  )

  const [
    areIntegrationSettingsOpen,
    handleOpenIntegrationSettings,
    handleCloseIntegrationSettings,
  ] = useToggle()

  const availableCompanyIntegrations = useMemo(() => {
    const integrations = companyData?.company.companyIntegrations ?? []
    return integrations.filter((integration) => integration.archivedAt === null)
  }, [companyData?.company.companyIntegrations])

  const gcPortalIntegration = contract
    ? getIntegrationOfFamily(contract, IntegrationTypeFamily.GC_PORTAL)
    : null

  const availableGcPortalIntegrations = useMemo(() => {
    return _.chain(availableCompanyIntegrations)
      .filter(({ type }) => getIntegrationTypeFamily(type) === IntegrationTypeFamily.GC_PORTAL)
      .filter(({ type }) => supportsProjectOnboarding(type))
      .orderBy(({ longName }) => longName, 'asc')
      .value()
  }, [availableCompanyIntegrations])
  const hasGcPortalIntegrations =
    availableGcPortalIntegrations.length > 0 || gcPortalIntegration !== null
  const showGcPortalField = hasGcPortalIntegrations && contract?.billingType !== BillingType.QUICK
  // We currently support the forced manual SOV editing setting only for Textura
  const allowAllowGcPortalManualSovEditing = gcPortalIntegration?.type === IntegrationType.TEXTURA

  const availableErpIntegrations = useMemo(() => {
    return _.chain(availableCompanyIntegrations)
      .filter(({ type }) => getIntegrationTypeFamily(type) === IntegrationTypeFamily.ERP)
      .filter(({ type }) => supportsProjectOnboarding(type))
      .orderBy(({ longName }) => longName, 'asc')
      .value()
  }, [availableCompanyIntegrations])

  const canAutoMarkAsPaidFromIntegrations = useMemo(() => {
    return contract?.integrations.some(({ type }) => supportsReadingPayments(type)) ?? false
  }, [contract?.integrations])

  const resetState = useCallback(() => {
    setSelectedErpProject(null)
    setSelectedErpTaxGroup(initialErpTaxGroup)
    setSelectedErpCustomer(initialErpCustomer)
    setSelectedErpDefaultGlAccount(initialErpDefaultGlAccount)
    setSelectedErpDefaultRetentionGlAccount(initialErpDefaultRetentionGlAccount)
    setHasEdited(false)
    setQuickbooksMetadata(initialQuickbooksMetadata)
    setEnableAutoMarkAsPaidFromIntegrations(initialEnableAutoMarkAsPaidFromIntegrations)
  }, [
    initialErpTaxGroup,
    initialErpCustomer,
    initialQuickbooksMetadata,
    initialEnableAutoMarkAsPaidFromIntegrations,
    initialErpDefaultGlAccount,
    initialErpDefaultRetentionGlAccount,
  ])

  const [defaultJobNameForQuickbooks, defaultCustomerNameForQuickbooks] = useMemo(() => {
    const projectName = contract?.project.name ?? ''
    const generalContractorName = contract ? getGeneralContractorName(contract.project) : ''
    return [
      projectName.length < QUICKBOOKS_JOB_MAX_CHAR_COUNT ? projectName : '',
      generalContractorName.length < QUICKBOOKS_CUSTOMER_MAX_CHAR_COUNT
        ? generalContractorName
        : '',
    ]
  }, [contract])

  const shouldUpdateFoundationMappings = useMemo(() => {
    return (
      erpIntegration?.type === IntegrationType.FOUNDATION &&
      (selectedErpDefaultGlAccount || selectedErpDefaultRetentionGlAccount || selectedErpIncomeType)
    )
  }, [
    erpIntegration?.type,
    selectedErpDefaultGlAccount,
    selectedErpDefaultRetentionGlAccount,
    selectedErpIncomeType,
  ])

  const handleSave = useCallback(async () => {
    if (!contract) {
      return
    }

    const doSave = async () => {
      // Add ERP integration if a new one is selected
      if (selectedErpProject && pendingErpIntegration) {
        await addIntegrationToContract({
          variables: {
            input: {
              id: contract.id,
              integration: {
                integrationProjectId: selectedErpProject.integrationProjectId,
                integrationContractId: selectedErpProject.integrationContractId,
                integrationAssociatedCompanyId: selectedErpProject.integrationAssociatedCompanyId,
                companyIntegrationId: pendingErpIntegration.id,
              },
            },
          },
        })
      }

      // Add GC portal integration if a new one is selected
      if (selectedGcPortalProject && pendingGcPortalIntegration) {
        await addIntegrationToContract({
          variables: {
            input: {
              id: contract.id,
              integration: {
                integrationProjectId: selectedGcPortalProject.integrationProjectId,
                integrationContractId: selectedGcPortalProject.integrationContractId,
                integrationAssociatedCompanyId:
                  selectedGcPortalProject.integrationAssociatedCompanyId,
                companyIntegrationId: pendingGcPortalIntegration.id,
              },
            },
          },
        })
      }

      if (selectedErpTaxGroup && erpIntegration && shouldShowTaxGroups) {
        const optimisticTaxGroup = {
          name: selectedErpTaxGroup.name,
          taxPercent: selectedErpTaxGroup.taxPercent,
        }
        let taxGroupIdMapping: Partial<Record<string, string>> = {}
        switch (erpIntegration.type) {
          case IntegrationType.SAGE_100_CONTRACTOR:
          case IntegrationType.SAGE_300_CRE:
            taxGroupIdMapping = { hh2TaxGroupId: selectedErpTaxGroup.integrationTaxGroupId }
            break
          case IntegrationType.SPECTRUM:
          case IntegrationType.SAGE_100_CONTRACTOR_AGAVE:
          case IntegrationType.SAGE_300_CRE_AGAVE:
            taxGroupIdMapping = { agaveTaxGroupId: selectedErpTaxGroup.integrationTaxGroupId }
            break
          case IntegrationType.ACUMATICA:
          case IntegrationType.COMPUTER_EASE_FILE:
          case IntegrationType.FOUNDATION:
          case IntegrationType.FOUNDATION_FILE:
          case IntegrationType.GC_PAY:
          case IntegrationType.SAGE_INTACCT:
          case IntegrationType.PROCORE:
          case IntegrationType.CMIC:
          case IntegrationType.QUICKBOOKS_ENTERPRISE_FILE:
          case IntegrationType.TEST:
          case IntegrationType.TEXTURA:
          case IntegrationType.VISTA:
            taxGroupIdMapping = {}
        }
        try {
          await setIntegrationTaxGroup({
            variables: {
              input: {
                integrationId: erpIntegration.id,
                integrationTaxGroupId: selectedErpTaxGroup.integrationTaxGroupId,
              },
            },
            optimisticResponse: {
              __typename: 'Mutation',
              setIntegrationTaxGroup: {
                ...erpIntegration,
                mappings: {
                  ...erpIntegration.mappings,
                  taxGroup: {
                    ...optimisticTaxGroup,
                    ...taxGroupIdMapping,
                  },
                },
              },
            },
          })
        } catch (error) {
          snackbar.showError(error.message)
        }
      }

      if (selectedErpCustomer && erpIntegration && shouldShowCustomerInput) {
        try {
          await setIntegrationCustomer({
            variables: {
              input: {
                integrationId: erpIntegration.id,
                integrationCustomerId: selectedErpCustomer.integrationCustomerId,
              },
            },
            optimisticResponse: {
              __typename: 'Mutation',
              setIntegrationCustomer: {
                ...erpIntegration,
                mappings: {
                  ...erpIntegration.mappings,
                  customer: {
                    hh2CustomerId: selectedErpCustomer.integrationCustomerId,
                    name: selectedErpCustomer.name,
                  },
                },
              },
            },
          })
        } catch (error) {
          snackbar.showError(error.message)
        }
      }

      if (shouldUpdateFoundationMappings && erpIntegration) {
        await updateIntegrationMappings({
          variables: {
            input: {
              id: erpIntegration.id,
              mappings: {
                ...erpIntegration.mappings,
                ...(selectedErpDefaultGlAccount && {
                  ledgerAccount: {
                    agaveAccountId: selectedErpDefaultGlAccount.integrationAccountId,
                    name: selectedErpDefaultGlAccount.name,
                    code: selectedErpDefaultGlAccount.code,
                  },
                }),
                ...(selectedErpDefaultRetentionGlAccount && {
                  retentionLedgerAccount: {
                    name: selectedErpDefaultRetentionGlAccount.name,
                    agaveSourceId: selectedErpDefaultRetentionGlAccount.code,
                  },
                }),
                ...(selectedErpIncomeType && {
                  incomeTypeId: selectedErpIncomeType.incomeTypeNumber,
                }),
              },
            },
          },
        })
      }

      if (
        erpIntegration?.type === IntegrationType.QUICKBOOKS_ENTERPRISE_FILE &&
        quickbooksMetadata
      ) {
        const projectName = quickbooksMetadata.projectName || defaultJobNameForQuickbooks
        const customerName = quickbooksMetadata.customerName || defaultCustomerNameForQuickbooks
        if (!projectName) {
          throw new Error(t(`${i18nBase}.missing_quickbooks_job`))
        }
        if (!customerName) {
          throw new Error(t(`${i18nBase}.missing_quickbooks_customer`))
        }

        await updateIntegrationMetadata({
          variables: {
            input: {
              id: erpIntegration.id,
              metadata: {
                // Include defaults for required settings in case they are missing
                includeRetention: QUICKBOOKS_DEFAULT_INCLUDE_RETENTION,
                combineAsSingleLine: QUICKBOOKS_DEFAULT_COMBINE_AS_SINGLE_LINE,
                singleLineDescription: QUICKBOOKS_DEFAULT_SINGLE_LINE_ITEM_DESCRIPTION,
                projectName,
                customerName,
                // Pass null instead of undefined so we unset any values if they've been cleared
                accountsReceivableAccount: quickbooksMetadata.accountsReceivableAccount ?? null,
                retentionAccountsReceivableAccount:
                  quickbooksMetadata.retentionAccountsReceivableAccount ?? null,
                progressItemName: quickbooksMetadata.progressItemName ?? null,
                progressItemIncomeAccount: quickbooksMetadata.progressItemIncomeAccount ?? null,
                retentionItemName: quickbooksMetadata.retentionItemName ?? null,
                retentionItemIncomeAccount: quickbooksMetadata.retentionItemIncomeAccount ?? null,
              },
            },
          },
        })
      }

      if (enableAutoMarkAsPaidFromIntegrations !== contract.enableAutoMarkAsPaidFromIntegrations) {
        await updateContract({
          variables: {
            input: {
              id: contract.id,
              enableAutoMarkAsPaidFromIntegrations,
            },
          },
        })
      }
    }

    setIsSaving(true)
    return doSave()
      .then(() => {
        setHasEdited(false)
        setIsSaving(false)
        snackbar.showSuccess(t(`${i18nBase}.updated`))
      })
      .catch((err) => {
        snackbar.showError(err.message)
        setIsSaving(false)
      })
  }, [
    contract,
    selectedErpProject,
    pendingErpIntegration,
    selectedGcPortalProject,
    pendingGcPortalIntegration,
    selectedErpTaxGroup,
    erpIntegration,
    shouldShowTaxGroups,
    selectedErpCustomer,
    shouldShowCustomerInput,
    shouldUpdateFoundationMappings,
    quickbooksMetadata,
    enableAutoMarkAsPaidFromIntegrations,
    addIntegrationToContract,
    setIntegrationTaxGroup,
    setIntegrationCustomer,
    updateIntegrationMappings,
    selectedErpDefaultGlAccount,
    selectedErpDefaultRetentionGlAccount,
    selectedErpIncomeType,
    defaultJobNameForQuickbooks,
    defaultCustomerNameForQuickbooks,
    updateIntegrationMetadata,
    t,
    updateContract,
    snackbar,
  ])

  const handleDeleteIntegration = useCallback(
    (integration: MinimalIntegrationProperties) => {
      const details =
        getIntegrationTypeFamily(integration.type) === IntegrationTypeFamily.ERP
          ? t(`${i18nBase}.delete_confirm.details_erp`)
          : t(`${i18nBase}.delete_confirm.details_gc_portal`)
      confirm({
        title: t(`${i18nBase}.delete_confirm.title`),
        details,
        maxWidth: 'sm',
        callback: (confirmed: boolean): void => {
          if (!confirmed) {
            return
          }
          snackbar.showLoading()
          deleteIntegration({
            variables: { id: integration.id },
            update: (cache) => {
              if (!contract) {
                return
              }
              cache.modify({
                id: cache.identify(contract),
                fields: {
                  integrations(existing, { readField, toReference }) {
                    const refs = toReferences(existing, toReference)
                    return refs.filter((ref) => {
                      const itemRef = readField('id', ref)
                      return itemRef !== integration.id
                    })
                  },
                },
              })
            },
          })
            .then(() => {
              snackbar.showSuccess()
              setIsEditing(false)
            })
            .catch((err) => snackbar.showError(err.message))
        },
      })
    },
    [confirm, contract, deleteIntegration, snackbar, t]
  )

  // Update the selected tax group when the mappings from the server load
  useEffect(() => {
    setSelectedErpTaxGroup(initialErpTaxGroup)
    setSelectedErpCustomer(initialErpCustomer)
  }, [initialErpTaxGroup, initialErpCustomer])

  // Update the default GL account when the mappings from the server load
  useEffect(() => {
    setSelectedErpDefaultGlAccount(initialErpDefaultGlAccount)
  }, [initialErpDefaultGlAccount])

  // Update the default income type when the mappings from the server load
  useEffect(() => {
    setSelectedErpIncomeType(initialErpIncomeType)
  }, [initialErpIncomeType])

  // Update the Quickbooks metadata if it changes
  useEffect(() => setQuickbooksMetadata(initialQuickbooksMetadata), [initialQuickbooksMetadata])

  // Fetch ERP integration projects if we're editing and an ERP integration exists
  useEffect(() => {
    if (!isEditing || !pendingErpIntegration || erpIntegration) {
      return
    }
    fetchErpProjects({
      variables: {
        input: {
          companyIntegrationId: pendingErpIntegration.id,
          includeInactive: false,
          filters: [],
        },
      },
    })
  }, [isEditing, pendingErpIntegration, fetchErpProjects, erpIntegration])

  // Fetch GC portal projects if we're editing and a GC portal integration exists
  useEffect(() => {
    if (!isEditing || !pendingGcPortalIntegration || gcPortalIntegration) {
      return
    }
    fetchGcPortalProjects({
      variables: {
        input: {
          companyIntegrationId: pendingGcPortalIntegration.id,
          includeInactive: false,
          filters: [],
        },
      },
    })
  }, [isEditing, pendingGcPortalIntegration, fetchGcPortalProjects, gcPortalIntegration])

  // Fetch tax groups and customers when entering edit mode if an ERP integration exists
  useEffect(() => {
    if (isEditing && erpIntegration && !integrationGroupsData && !integrationCustomersData) {
      if (supportsReadingTaxGroups(erpIntegration.type)) {
        fetchErpTaxGroups({
          variables: {
            input: { companyIntegrationId: erpIntegration.companyIntegration.id },
          },
        })
      }
      if (supportsSettingCustomer(erpIntegration.type)) {
        fetchCustomers({ variables: { input: { integrationId: erpIntegration.id } } })
      }
    }
  }, [
    isEditing,
    erpIntegration,
    fetchErpTaxGroups,
    integrationGroupsData,
    integrationCustomersData,
    fetchCustomers,
    fetchGeneralLedgerAccounts,
  ])

  // Fetch general ledger accounts if the integration is a Foundation Integration and we're editing
  // or if there is no stored name for the default or retention GL account
  const shouldFetchGeneralLedgerAccounts = useMemo(() => {
    return (
      erpIntegration?.type === IntegrationType.FOUNDATION &&
      (isEditing ||
        !storedIntegrationGeneralLedgerAccountName ||
        !storedIntegrationRetentionGeneralLedgerAccountName)
    )
  }, [
    erpIntegration,
    isEditing,
    storedIntegrationGeneralLedgerAccountName,
    storedIntegrationRetentionGeneralLedgerAccountName,
  ])

  useEffect(() => {
    if (shouldFetchGeneralLedgerAccounts && erpIntegration) {
      fetchGeneralLedgerAccounts({
        variables: { companyIntegrationId: erpIntegration.companyIntegration.id },
      })
    }
  }, [erpIntegration, fetchGeneralLedgerAccounts, shouldFetchGeneralLedgerAccounts])

  // Fetch income types if the integration is a Foundation Integration
  // This query returns quickly and we need it to decide if we should show the income type input
  // or not, so we run this even if we're not editing
  useEffect(() => {
    if (erpIntegration?.type === IntegrationType.FOUNDATION) {
      fetchIncomeTypes({
        variables: {
          input: { companyIntegrationId: erpIntegration.companyIntegration.id },
        },
      })
    }
  }, [erpIntegration, fetchIncomeTypes])

  const integrationProjectName = useCallback(
    (integration: Pick<Integration, 'id' | 'longName' | 'mappings'>) => {
      let name = integration.longName
      const mappings = integration.mappings as { project?: { name?: string } }
      const projectName = mappings.project?.name
      if (projectName) {
        name += `: ${projectName}`
      }
      return name
    },
    []
  )

  const bulkSaveProps = useMemo(
    () => ({
      onSave: handleSave,
      onCancel: resetState,
      hasEdited,
    }),
    [handleSave, hasEdited, resetState]
  )

  // Quickbooks has extra fields with long labels, so we extend the label width to match
  const labelWidth =
    erpIntegration?.type === IntegrationType.QUICKBOOKS_ENTERPRISE_FILE
      ? QUICKBOOKS_SETTINGS_LABEL_WIDTH
      : undefined

  return (
    <>
      <div className={classes.root}>
        <SettingsHeader
          title={t(`${i18nBase}.title`)}
          canEdit={canEdit}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          isSaving={isSaving}
          bulkSaveProps={bulkSaveProps}
        />

        {availableErpIntegrations.length === 0 && !erpIntegration && !showGcPortalField && (
          <SitelineText variant="body1" color="grey50">
            {t(`${i18nBase}.no_integrations`)}
          </SitelineText>
        )}
        {(availableErpIntegrations.length > 0 || erpIntegration) && (
          <>
            <SettingsRow
              label={t(`${i18nBase}.erp`)}
              isLoading={!contract}
              value={erpIntegration ? integrationProjectName(erpIntegration) : null}
              labelWidth={labelWidth}
              editingValue={
                <>
                  {erpIntegration && (
                    <SitelineText
                      variant="body1"
                      color="grey90"
                      endIcon={
                        <Tooltip title={t(`${i18nBase}.remove`)} placement="top">
                          <IconButton
                            className={classes.integrationIcon}
                            onClick={() => handleDeleteIntegration(erpIntegration)}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      }
                    >
                      {integrationProjectName(erpIntegration)}
                    </SitelineText>
                  )}
                  {!erpIntegration && (
                    <Select
                      disabled={isSaving}
                      variant="outlined"
                      value={pendingErpIntegration?.id ?? ''}
                      onChange={(event) => {
                        const integration = availableErpIntegrations.find(
                          (integration) => integration.id === event.target.value
                        )
                        setPendingErpIntegration(integration ?? null)
                      }}
                      className={classes.integrationSelect}
                      displayEmpty
                      renderValue={(value: string) => {
                        const integration = availableErpIntegrations.find(
                          (integration) => integration.id === value
                        )
                        return integration?.longName ?? t(`${i18nBase}.none`)
                      }}
                    >
                      {availableErpIntegrations.map((integration) => (
                        <MenuItem key={integration.id} value={integration.id}>
                          {integration.longName}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  {!erpIntegration && pendingErpIntegration && (
                    <LinkIntegrationProject
                      integration={pendingErpIntegration}
                      projects={erpProjects}
                      selectedProject={selectedErpProject}
                      onSelectedProjectChange={(project) => {
                        setSelectedErpProject(project)
                        setHasEdited(true)
                      }}
                      loadingProjects={fetchingErpProjects}
                      hasImportError={erpProjectsError !== undefined}
                      hideDescription
                      fullWidth
                      className={classes.projectSelect}
                    />
                  )}
                </>
              }
              isEditing={isEditing}
              className={classes.integration}
            />
            {shouldShowTaxGroups && (
              <SettingsRow
                label={t(`${i18nBase}.tax_group`)}
                isLoading={!contract}
                value={currentErpTaxGroup ? renderTaxGroup(currentErpTaxGroup) : null}
                editingValue={
                  <>
                    {!erpIntegration?.id && (
                      <SitelineText variant="body1" color="grey50">
                        —
                      </SitelineText>
                    )}
                    {!integrationGroupsData && erpIntegration?.id && (
                      <Skeleton variant="rectangular" width={80} height={24} />
                    )}
                    {integrationGroupsData && (
                      <div className={classes.editing}>
                        <Select
                          disabled={isSaving}
                          variant="outlined"
                          value={selectedErpTaxGroup?.integrationTaxGroupId ?? ''}
                          onChange={(event) => {
                            const selectedTaxGroup = integrationGroups.find(
                              (taxGroup) => taxGroup.integrationTaxGroupId === event.target.value
                            )
                            setSelectedErpTaxGroup(selectedTaxGroup ?? null)
                            setHasEdited(true)
                          }}
                          className="select"
                          displayEmpty
                          renderValue={(value: string) => {
                            const selectedTaxGroup = integrationGroups.find(
                              (taxGroup) => taxGroup.integrationTaxGroupId === value
                            )
                            return selectedTaxGroup
                              ? renderTaxGroup(selectedTaxGroup)
                              : t(`${i18nBase}.none`)
                          }}
                        >
                          {integrationGroups.map((taxGroup) => (
                            <MenuItem
                              key={taxGroup.integrationTaxGroupId}
                              value={taxGroup.integrationTaxGroupId}
                            >
                              {renderTaxGroup(taxGroup)}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    )}
                  </>
                }
                isEditing={isEditing}
              />
            )}
            {shouldShowCustomerInput && (
              <SettingsRow
                label={t(`${i18nBase}.customer`)}
                isLoading={!contract}
                value={currentErpCustomer ? currentErpCustomer.name : null}
                editingValue={
                  <>
                    {!erpIntegration?.id && (
                      <SitelineText variant="body1" color="grey50">
                        —
                      </SitelineText>
                    )}
                    {!integrationCustomersData && erpIntegration?.id && (
                      <Skeleton variant="rectangular" width={80} height={24} />
                    )}
                    {integrationCustomersData && (
                      <div className={classes.editing}>
                        <Autocomplete
                          disabled={isSaving}
                          value={selectedErpCustomer}
                          options={integrationCustomers}
                          onChange={(ev, option) => {
                            if (!option) {
                              return
                            }
                            setSelectedErpCustomer(option)
                            setHasEdited(true)
                          }}
                          className="select"
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField variant="outlined" type="label" {...params} />
                          )}
                          renderOption={(props, option) => (
                            <li {...props} key={option.integrationCustomerId}>
                              <SitelineText variant="secondary">
                                {option.name}
                                <SitelineText variant="secondary" color="grey50">
                                  {option.code && ` · ${option.code}`}
                                </SitelineText>
                              </SitelineText>
                            </li>
                          )}
                        />
                      </div>
                    )}
                  </>
                }
                isEditing={isEditing}
              />
            )}
          </>
        )}
        {erpIntegration?.type === IntegrationType.FOUNDATION && (
          <>
            <SettingsRow
              label={t(`${i18nBase}.default_gl_account`)}
              isLoading={!contract}
              value={
                <Row>
                  <SitelineText variant="body1">
                    {selectedErpDefaultGlAccount
                      ? selectedErpDefaultGlAccount.name
                      : storedIntegrationGeneralLedgerAccountName}
                  </SitelineText>
                  <Spacer minWidth={4} maxWidth={4} />
                  <SitelineText variant="body1" color="grey50">
                    {integrationGeneralLedgerAccountCode &&
                      `  · ${integrationGeneralLedgerAccountCode}`}
                  </SitelineText>
                </Row>
              }
              editingValue={
                <>
                  {!erpIntegration.id && (
                    <SitelineText variant="body1" color="grey50">
                      —
                    </SitelineText>
                  )}
                  {!integrationGeneralLedgerAccountsData && erpIntegration.id && (
                    <Skeleton variant="rectangular" width={80} height={24} />
                  )}
                  {integrationGeneralLedgerAccountsData && (
                    <div className={classes.editing}>
                      <Autocomplete
                        disabled={isSaving}
                        value={selectedErpDefaultGlAccount}
                        options={integrationGeneralLedgerAccounts}
                        onChange={(ev, option) => {
                          if (!option) {
                            return
                          }
                          setSelectedErpDefaultGlAccount(option)
                          setHasEdited(true)
                        }}
                        className="select"
                        getOptionLabel={getLedgerAccountLabel}
                        renderInput={(params) => (
                          <TextField variant="outlined" type="label" {...params} />
                        )}
                        renderOption={(props, option) => (
                          <li {...props} key={option.integrationAccountId}>
                            <SitelineText variant="secondary">
                              {option.name}
                              <SitelineText variant="secondary" color="grey50">
                                {option.code && ` · ${option.code}`}
                              </SitelineText>
                            </SitelineText>
                          </li>
                        )}
                      />
                    </div>
                  )}
                </>
              }
              isEditing={isEditing}
            />
            <SettingsRow
              label={t(`${i18nBase}.retention_default_gl_account`)}
              isLoading={!contract}
              value={
                <Row>
                  <SitelineText variant="body1">
                    {integrationRetentionGeneralLedgerAccountName}
                  </SitelineText>
                  <Spacer minWidth={4} maxWidth={4} />
                  <SitelineText variant="body1" color="grey50">
                    {integrationRetentionGeneralLedgerAccountCode &&
                      integrationRetentionGeneralLedgerAccountName !==
                        integrationRetentionGeneralLedgerAccountCode &&
                      `  · ${integrationRetentionGeneralLedgerAccountCode}`}
                  </SitelineText>
                </Row>
              }
              editingValue={
                <>
                  {!erpIntegration.id && (
                    <SitelineText variant="body1" color="grey50">
                      —
                    </SitelineText>
                  )}
                  {!integrationGeneralLedgerAccountsData && erpIntegration.id && (
                    <Skeleton variant="rectangular" width={80} height={24} />
                  )}
                  {integrationGeneralLedgerAccountsData && (
                    <div className={classes.editing}>
                      <Autocomplete
                        disabled={isSaving}
                        value={selectedErpDefaultRetentionGlAccount}
                        options={integrationGeneralLedgerAccounts}
                        onChange={(ev, option) => {
                          if (!option) {
                            return
                          }
                          setSelectedErpDefaultRetentionGlAccount(option)
                          setHasEdited(true)
                        }}
                        className="select"
                        renderInput={(params) => (
                          <TextField variant="outlined" type="label" {...params} />
                        )}
                        renderOption={(props, option) => (
                          <li {...props} key={option.integrationAccountId}>
                            <SitelineText variant="secondary">
                              {option.name}
                              <SitelineText variant="secondary" color="grey50">
                                {option.code && ` · ${option.code}`}
                              </SitelineText>
                            </SitelineText>
                          </li>
                        )}
                        getOptionLabel={getLedgerAccountLabel}
                      />
                    </div>
                  )}
                </>
              }
              isEditing={isEditing}
            />
            {isIncomeTypeEnabled && (
              <SettingsRow
                label={t(`${i18nBase}.income_type`)}
                isLoading={!contract}
                value={incomeTypeName ?? '-'}
                editingValue={
                  <>
                    {!erpIntegration.id && (
                      <SitelineText variant="body1" color="grey50">
                        —
                      </SitelineText>
                    )}
                    {!integrationIncomeTypesData && erpIntegration.id && (
                      <Skeleton variant="rectangular" width={80} height={24} />
                    )}
                    {integrationIncomeTypesData && (
                      <div className={classes.editing}>
                        <Autocomplete
                          disabled={isSaving}
                          value={selectedErpIncomeType}
                          options={integrationIncomeTypes}
                          onChange={(ev, option) => {
                            if (!option) {
                              return
                            }
                            setSelectedErpIncomeType(option)
                            setHasEdited(true)
                          }}
                          className="select"
                          getOptionLabel={getIncomeTypeLabel}
                          renderInput={(params) => (
                            <TextField variant="outlined" type="label" {...params} />
                          )}
                          renderOption={(props, option) => (
                            <li {...props} key={option.incomeTypeNumber}>
                              <SitelineText variant="secondary">
                                {getIncomeTypeLabel(option)}
                              </SitelineText>
                            </li>
                          )}
                        />
                      </div>
                    )}
                  </>
                }
                isEditing={isEditing}
              />
            )}
          </>
        )}

        {erpIntegration?.type === IntegrationType.QUICKBOOKS_ENTERPRISE_FILE &&
          quickbooksMetadata && (
            <QuickbooksProjectSettings
              type="full"
              metadata={quickbooksMetadata}
              onMetadataChange={(newProjectMetadata) => {
                const newMetadata = { ...quickbooksMetadata, ...newProjectMetadata }
                if (!_.isEqual(newMetadata, quickbooksMetadata)) {
                  setHasEdited(true)
                  setQuickbooksMetadata(newMetadata)
                }
              }}
              isEditing={isEditing}
              className={classes.quickbooks}
              jobPlaceholder={defaultJobNameForQuickbooks}
              customerPlaceholder={defaultCustomerNameForQuickbooks}
            />
          )}
        {(availableErpIntegrations.length > 0 || erpIntegration) && showGcPortalField && (
          <div className={classes.divider} />
        )}
        {showGcPortalField && (
          <SettingsRow
            label={t(`${i18nBase}.gc_portal`)}
            isLoading={!contract}
            value={gcPortalIntegration ? integrationProjectName(gcPortalIntegration) : null}
            editingValue={
              <>
                {gcPortalIntegration && (
                  <SitelineText
                    variant="body1"
                    color="grey90"
                    endIcon={
                      <Row alignItems="center" gap={2}>
                        <Tooltip title={t(`${i18nBase}.remove`)} placement="top">
                          <IconButton
                            className={classes.integrationIcon}
                            onClick={() => handleDeleteIntegration(gcPortalIntegration)}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        {allowAllowGcPortalManualSovEditing && (
                          <IconButton
                            className={classes.integrationIcon}
                            onClick={handleOpenIntegrationSettings}
                          >
                            <SettingsIcon fontSize="small" />
                          </IconButton>
                        )}
                      </Row>
                    }
                  >
                    {integrationProjectName(gcPortalIntegration)}
                  </SitelineText>
                )}
                {!gcPortalIntegration && (
                  <Select
                    disabled={isSaving}
                    variant="outlined"
                    value={pendingGcPortalIntegration?.id ?? ''}
                    onChange={(event) => {
                      const integration = availableGcPortalIntegrations.find(
                        (integration) => integration.id === event.target.value
                      )
                      setPendingGcPortalIntegration(integration ?? null)
                    }}
                    className={classes.integrationSelect}
                    displayEmpty
                    renderValue={(value: string) => {
                      const integration = availableGcPortalIntegrations.find(
                        (integration) => integration.id === value
                      )
                      return integration?.longName ?? t(`${i18nBase}.none`)
                    }}
                  >
                    {availableGcPortalIntegrations.map((integration) => (
                      <MenuItem key={integration.id} value={integration.id}>
                        {integration.longName}
                      </MenuItem>
                    ))}
                  </Select>
                )}
                {!gcPortalIntegration && pendingGcPortalIntegration && (
                  <>
                    <LinkIntegrationProject
                      integration={pendingGcPortalIntegration}
                      projects={gcPortalProjects}
                      selectedProject={selectedGcPortalProject}
                      onSelectedProjectChange={(project) => {
                        setSelectedGcPortalProject(project)
                        setHasEdited(true)
                      }}
                      loadingProjects={fetchingGcPortalProjects}
                      hasImportError={gcPortalProjectsError !== undefined}
                      hideDescription
                      fullWidth
                      className={classes.projectSelect}
                    />
                    {selectedGcPortalProject && (
                      <SitelineAlert severity="warning" className={classes.gcLinkWarning}>
                        {t(`${i18nBase}.gc_link_warning`)}
                      </SitelineAlert>
                    )}
                  </>
                )}
              </>
            }
            isEditing={isEditing}
            className={classes.integration}
          />
        )}
        {canAutoMarkAsPaidFromIntegrations && (
          <>
            <div className={classes.divider} />
            <SettingsRow
              label={t(`${i18nBase}.enable_mark_as_paid`)}
              isLoading={!contract}
              value={
                enableAutoMarkAsPaidFromIntegrations
                  ? t(`${i18nBase}.enable_mark_as_paid_on`)
                  : t(`${i18nBase}.enable_mark_as_paid_off`)
              }
              editingValue={
                <Switch
                  checked={enableAutoMarkAsPaidFromIntegrations}
                  onChange={(event) => {
                    setEnableAutoMarkAsPaidFromIntegrations(event.target.checked)
                    setHasEdited(true)
                  }}
                  className={classes.switch}
                />
              }
              isEditing={isEditing}
            />
          </>
        )}
      </div>

      {gcPortalIntegration && (
        <IntegrationSettings
          integration={gcPortalIntegration}
          open={areIntegrationSettingsOpen}
          onClose={handleCloseIntegrationSettings}
        />
      )}
    </>
  )
}
