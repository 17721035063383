import { gql } from '@apollo/client'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import LaunchIcon from '@mui/icons-material/Launch'
import {
  Autocomplete,
  Collapse,
  FormControl,
  Link,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Tooltip,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import clsx from 'clsx'
import _ from 'lodash'
import moment from 'moment-timezone'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CompanyIntegrationMetadataFoundation,
  ContractPaymentTermsType,
  DAY_FORMAT,
  MAX_FOUNDATION_INVOICE_NUMBER_LENGTH,
  TaxCalculationType,
  getDefaultInvoiceCode,
  getDefaultInvoiceDates,
  integrationTypes,
} from 'siteline-common-all'
import { Row, SitelineText, colors, makeStylesFast } from 'siteline-common-web'
import {
  DatePickerInput,
  DatePickerValue,
  isMissingDate,
  makeDatePickerValue,
} from '../../../common/components/DatePickerInput'
import { DollarNumberFormat } from '../../../common/components/NumberFormat'
import { SitelineAlert } from '../../../common/components/SitelineAlert'
import {
  STANDARD_FIXED_DIALOG_TOP,
  SitelineDialog,
  SitelineDialogProps,
} from '../../../common/components/SitelineDialog'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import {
  GetIntegrationIncomeTypeNumbersQuery,
  MinimalIntegrationProperties,
  useGetIntegrationGeneralLedgerAccountsQuery,
  useGetIntegrationIncomeTypeNumbersQuery,
  useGetPayAppQuery,
} from '../../../common/graphql/apollo-operations'
import { isWriteSyncInProgress, useWriteSync } from '../../../common/util/Integration'
import { trackIntegrationSyncDialogReset } from '../../../common/util/MetricsTracking'
import { payAppHasAnyProgressBilling } from '../../../common/util/PayApp'
import { LedgerAccountOptions } from './LedgerAccountOptions'
import { GeneralLedgerAccount } from './SyncSage100CDialog'
import { WriteSyncDialogContent } from './WriteSyncDialogContent'

const SECTION_WIDTH = 600
const DROPDOWN_WIDTH = 190

const useStyles = makeStylesFast((theme: Theme) => ({
  help: {
    marginTop: theme.spacing(1),
  },
  root: {
    '& .MuiDialog-paper': {
      maxWidth: 720,
    },
    '&.wideSovView': {
      '& .MuiDialog-paper': {
        maxWidth: 1200,
      },
    },
    '& .container': {
      display: 'flex',
      flexDirection: 'column',
      '& .warning': {
        marginBottom: theme.spacing(2),
      },
      '& .row': {
        display: 'flex',
        '& .leftSection': {
          width: SECTION_WIDTH,
          display: 'flex',
        },
        '& .amountsRow': {
          gap: theme.spacing(6),
        },
        '& .tooltip': {
          width: 16,
          height: 16,
          color: colors.grey50,
        },
      },
      '& .noDefaultNumber': {
        marginTop: theme.spacing(-1),
      },
      '& .setting': {
        display: 'flex',
        gap: theme.spacing(1),
        flexDirection: 'column',
        margin: theme.spacing(1.5, 0),
        '&.doubleGap': {
          gap: theme.spacing(2),
        },
      },
      '& .MuiSkeleton-root': {
        height: 40,
      },
    },
    '& .dateInput': {
      width: DROPDOWN_WIDTH,
      marginRight: theme.spacing(2),
    },
    '& .invoiceNumber': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& .generateNumber': {
        marginLeft: theme.spacing(1),
      },
    },
    '& .divider': {
      margin: theme.spacing(1.5, 0),
    },
    '& .numberInput': {
      backgroundColor: colors.white,
      border: `1px solid ${colors.grey30}`,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(1),
      // Match height of the other inputs
      height: 39,
      width: '100%',
      ...theme.typography.body1,
      '&:hover': {
        borderColor: colors.grey90,
      },
    },
  },
  syncSuccess: {
    '& .MuiDialog-paper': {
      maxWidth: 600,
    },
    '& .MuiDialogContent-root': {
      margin: theme.spacing(-2.5),
    },
  },
  autocomplete: {
    backgroundColor: colors.white,
    '& .MuiSvgIcon-root': {
      color: colors.grey50,
    },
  },
  '& .invoiceCode': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& .MuiInputBase-root': {
      maxWidth: 160,
    },
    '& .MuiFormHelperText-root': {
      margin: theme.spacing(0, 1),
    },
  },
}))

gql`
  query getIntegrationIncomeTypeNumbers($input: IntegrationIncomeTypeNumbersInput!) {
    integrationIncomeTypeNumbers(input: $input) {
      integrationIncomeTypeNumberId
      incomeTypeNumber
      description
    }
  }
`

export enum LedgerAllocationType {
  SINGLE_LEDGER_ACCOUNT = 'SINGLE_LEDGER_ACCOUNT',
  ASSIGN_LEDGER_ACCOUNT_BY_LINE_ITEM = 'ASSIGN_LEDGER_ACCOUNT_BY_LINE_ITEM',
}

const HELP_CENTER_URL =
  'https://support.siteline.com/hc/en-us/articles/25668398034196-Set-up-Foundation-Integration-for-your-company'
const i18nBase = 'integrations.foundation_sync_dialog'
export type IncomeTypeNumberProperties =
  GetIntegrationIncomeTypeNumbersQuery['integrationIncomeTypeNumbers'][number]

const DEFAULT_LEDGER_ALLOCATION_TYPE = LedgerAllocationType.SINGLE_LEDGER_ACCOUNT

interface SyncFoundationDialogProps {
  open: boolean
  onClose: () => void
  integration: MinimalIntegrationProperties
  payAppId: string
}

export function getLedgerAccountLabel(option: GeneralLedgerAccount): string {
  return `${option.name} ${option.code ? ` · ${option.code}` : ''}`
}

export function getIncomeTypeLabel(option: IncomeTypeNumberProperties): string {
  return `${option.incomeTypeNumber} (${option.description})`
}

/**
 * Dialog that gives options to select from when syncing their invoice to Foundation.
 */
export function SyncFoundationDialog({
  open,
  onClose,
  integration,
  payAppId,
}: SyncFoundationDialogProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { id: projectId, projectNumber, contract, timeZone } = useProjectContext()
  const { companyAgingIntervalType } = useCompanyContext()
  const [invoiceNumber, setInvoiceNumber] = useState<string>('')
  const [invoiceDate, setInvoiceDate] = useState<DatePickerValue>(makeDatePickerValue(null))
  const [dueDate, setDueDate] = useState<DatePickerValue>(makeDatePickerValue(null))
  const [useAutoCode, setUseAutoCode] = useState<boolean>(true)

  const integrationLongName = integration.longName
  const {
    data,
    loading: loadingPayApp,
    error,
  } = useGetPayAppQuery({ variables: { payAppId }, skip: !open })

  const incomeMappings = integration.mappings as integrationTypes.IntegrationMappingsFoundation
  const companyIntegrationMetadata = integration.companyIntegration
    .metadata as CompanyIntegrationMetadataFoundation
  const defaultLedgerAccountId =
    incomeMappings.ledgerAccount?.agaveAccountId ??
    companyIntegrationMetadata.ledgerAccount?.agaveAccountId ??
    null
  const defaultRetentionLedgerAccountCode =
    incomeMappings.retentionLedgerAccount?.agaveSourceId ??
    companyIntegrationMetadata.retentionLedgerAccount?.agaveSourceId ??
    null
  const [ledgerAccountId, setLedgerAccountId] = useState<string | null>(defaultLedgerAccountId)
  const [retentionLedgerAccountCode, setRetentionLedgerAccountCode] = useState<string | null>(
    defaultRetentionLedgerAccountCode
  )
  const payApp = data?.payApp
  const showMultipleTaxCodesWarning =
    contract?.taxCalculationType === TaxCalculationType.MULTIPLE_TAX_GROUPS
  const multipleTaxCodesWarning = showMultipleTaxCodesWarning && t(`${i18nBase}.multiple_tax_codes`)

  const initialLedgerType: LedgerAllocationType = useMemo(() => {
    if (incomeMappings.lineItemMappings) {
      return LedgerAllocationType.ASSIGN_LEDGER_ACCOUNT_BY_LINE_ITEM
    } else if (incomeMappings.ledgerAccount) {
      return LedgerAllocationType.SINGLE_LEDGER_ACCOUNT
    } else {
      return DEFAULT_LEDGER_ALLOCATION_TYPE
    }
  }, [incomeMappings.ledgerAccount, incomeMappings.lineItemMappings])

  const [ledgerType, setLedgerType] = useState<LedgerAllocationType>(initialLedgerType)

  // If the ledger type in the integration mappings changes, update the state
  useEffect(() => setLedgerType(initialLedgerType), [initialLedgerType])

  const initialLedgerAccountMappings = useMemo(() => {
    if (incomeMappings.lineItemMappings) {
      return incomeMappings.lineItemMappings
    }
    if (!ledgerAccountId) {
      return []
    }
    const progress = data?.payApp.progress ?? []
    return progress
      .filter((progressLineItem) => progressLineItem.currentBilled !== 0)
      .map((progressLineItem) => {
        return {
          sitelineLineItemId: progressLineItem.sovLineItem.id,
          agaveLedgerAccountId: ledgerAccountId,
        }
      })
  }, [data?.payApp.progress, incomeMappings.lineItemMappings, ledgerAccountId])

  const [ledgerAccountMapping, setLedgerAccountMapping] = useState<
    integrationTypes.FoundationLineItemMapping[]
  >(initialLedgerAccountMappings)

  // Whenever the initial mappings load, update the state
  useEffect(() => {
    setLedgerAccountMapping(initialLedgerAccountMappings)
  }, [initialLedgerAccountMappings])

  const defaultIncomeTypeId = incomeMappings.incomeTypeId ?? null
  const [incomeTypeId, setIncomeTypeId] = useState<string | null>(defaultIncomeTypeId)

  const { data: accountsData, loading: loadingAccounts } =
    useGetIntegrationGeneralLedgerAccountsQuery({
      variables: { companyIntegrationId: integration.companyIntegration.id },
      skip: !open,
    })
  const { data: incomeTypeNumbersData, loading: loadingIncomeTypes } =
    useGetIntegrationIncomeTypeNumbersQuery({
      variables: { input: { companyIntegrationId: integration.companyIntegration.id } },
      skip: !open,
    })

  const hasAnyBilling = useMemo(() => {
    const payApp = data?.payApp
    if (!payApp) {
      return false
    }
    return payAppHasAnyProgressBilling(payApp)
  }, [data])

  const payAppTotal = useMemo(() => data?.payApp.currentBilled ?? 0, [data])
  const payAppRetention = useMemo(() => data?.payApp.currentRetention ?? 0, [data])
  const payAppTax = useMemo(
    () =>
      // Only calculate tax amount if the contract has a single tax group
      // Otherwise, the customer will need to enter it manually in Foundation
      contract?.taxCalculationType === TaxCalculationType.SINGLE_TAX_GROUP
        ? (data?.payApp.amountDueTaxAmount ?? 0)
        : 0,
    [contract?.taxCalculationType, data?.payApp.amountDueTaxAmount]
  )

  const shouldShowRetentionAccountPicker = useMemo(
    () => data && data.payApp.currentRetention !== 0,
    [data]
  )
  const ledgerAccounts = useMemo(() => {
    const accounts = accountsData?.integrationGeneralLedgerAccounts ?? []
    return _.orderBy(accounts, (account) => account.name)
  }, [accountsData])

  const ledgerAccount = useMemo(
    () =>
      ledgerAccounts.find((account) => account.integrationAccountId === ledgerAccountId) ?? null,
    [ledgerAccountId, ledgerAccounts]
  )

  const retentionLedgerAccount = useMemo(
    () => ledgerAccounts.find((account) => account.code === retentionLedgerAccountCode) ?? null,
    [ledgerAccounts, retentionLedgerAccountCode]
  )
  const incomeTypeNumbers = useMemo(
    () => incomeTypeNumbersData?.integrationIncomeTypeNumbers ?? [],
    [incomeTypeNumbersData?.integrationIncomeTypeNumbers]
  )
  const incomeTypeNumber = useMemo(
    () =>
      incomeTypeNumbers.find((number) => number.integrationIncomeTypeNumberId === incomeTypeId) ??
      null,
    [incomeTypeId, incomeTypeNumbers]
  )
  const sovLineItemIdsWithProgress = useMemo(
    () =>
      payApp
        ? payApp.progress
            .filter((progressLineItem) => progressLineItem.currentBilled !== 0)
            .map((progressLineItem) => progressLineItem.sovLineItem.id)
        : [],
    [payApp]
  )

  // Income type number is a required field in the sync payload if the customer has enabled income
  // types in Foundation, which is the case iff income type numbers are returned from the Agave
  // query. If the query returns successfully with an empty array, we assume income type numbers
  // are not enabled for the company.
  // See https://sitelinetech.slack.com/archives/C03RTRXJYCV/p1714156307967409
  const isIncomeTypeEnabled = incomeTypeNumbersData && incomeTypeNumbers.length > 0
  const isMissingIncomeType = isIncomeTypeEnabled && !incomeTypeNumber

  const allLineItemsMapped = useMemo(() => {
    const mappedLineItems = ledgerAccountMapping.map((item) => item.sitelineLineItemId)
    return sovLineItemIdsWithProgress.every((item) => mappedLineItems.includes(item))
  }, [ledgerAccountMapping, sovLineItemIdsWithProgress])

  const payload = useMemo((): integrationTypes.WriteSyncPayloadPayAppFoundation | null => {
    const isMissingSingleLedgerAccount =
      ledgerType === LedgerAllocationType.SINGLE_LEDGER_ACCOUNT && !ledgerAccountId
    const isMissingLedgerAccountByLineItem =
      ledgerType === LedgerAllocationType.ASSIGN_LEDGER_ACCOUNT_BY_LINE_ITEM && !allLineItemsMapped
    if (
      !invoiceDate.date ||
      !dueDate.date ||
      isMissingSingleLedgerAccount ||
      isMissingLedgerAccountByLineItem ||
      (shouldShowRetentionAccountPicker && !retentionLedgerAccountCode) ||
      isMissingIncomeType
    ) {
      return null
    }
    let integrationLedgerAccountCodeForRetention: string | undefined
    if (shouldShowRetentionAccountPicker) {
      integrationLedgerAccountCodeForRetention = retentionLedgerAccountCode ?? undefined
    }
    const payload: integrationTypes.WriteSyncPayloadPayAppFoundation = {
      type: 'payAppFoundation',
      payAppId,
      invoiceDate: invoiceDate.date.format(DAY_FORMAT),
      ...(!useAutoCode && { invoiceNumber }),
      dueDate: dueDate.date.format(DAY_FORMAT),
      ...(shouldShowRetentionAccountPicker && {
        integrationLedgerAccountCodeForRetention,
        integrationLedgerAccountNameForRetention: retentionLedgerAccount?.name ?? '',
      }),
      ...(ledgerType === LedgerAllocationType.SINGLE_LEDGER_ACCOUNT &&
        ledgerAccountId && {
          integrationLedgerAccountId: ledgerAccountId,
          integrationLedgerAccountName: ledgerAccount?.name ?? '',
        }),
      ...(ledgerType === LedgerAllocationType.ASSIGN_LEDGER_ACCOUNT_BY_LINE_ITEM &&
        allLineItemsMapped &&
        !_.isNil(ledgerAccountMapping) && {
          lineItemMappings: ledgerAccountMapping,
        }),
      incomeTypeId: incomeTypeNumber?.integrationIncomeTypeNumberId ?? null,
    }
    return payload
  }, [
    ledgerType,
    ledgerAccountId,
    allLineItemsMapped,
    invoiceDate.date,
    dueDate.date,
    shouldShowRetentionAccountPicker,
    retentionLedgerAccountCode,
    isMissingIncomeType,
    payAppId,
    useAutoCode,
    invoiceNumber,
    retentionLedgerAccount,
    ledgerAccount,
    ledgerAccountMapping,
    incomeTypeNumber?.integrationIncomeTypeNumberId,
  ])

  const { sync, status, reset: resetSync } = useWriteSync({ integration })

  const resetDates = useCallback(() => {
    if (!data) {
      return
    }
    const paymentTerms = _.isNumber(contract?.paymentTerms) ? contract.paymentTerms : null
    const { invoiceDate, dueDate } = getDefaultInvoiceDates({
      agingIntervalType: companyAgingIntervalType,
      timeZone,
      paymentTerms,
      billingEnd: moment.tz(data.payApp.billingEnd, timeZone),
      submittedAt: data.payApp.lastSubmitted
        ? moment.tz(data.payApp.lastSubmitted.statusUpdatedAt, timeZone)
        : null,
    })
    setInvoiceDate(makeDatePickerValue(invoiceDate))
    setDueDate(makeDatePickerValue(dueDate))
  }, [companyAgingIntervalType, contract?.paymentTerms, data, timeZone])

  const resetDialog = useCallback(
    (trackClick: boolean) => {
      if (!data) {
        return
      }

      // Reset all dates
      resetDates()

      if (trackClick) {
        trackIntegrationSyncDialogReset({ projectId, payAppId, integrationLongName })
      }
    },
    [data, resetDates, projectId, payAppId, integrationLongName]
  )

  // When we have all the data, try to auto-populate as best as we can. Always reset the dialog
  // when you change between pay apps.
  useEffect(() => {
    resetDialog(false)
  }, [resetDialog, payAppId])

  // Update the invoice date if the pay app dates or company aging settings change
  useEffect(() => {
    resetDates()
  }, [resetDates])

  const defaultInvoiceNumber = useMemo(
    () =>
      getDefaultInvoiceCode({
        internalProjectNumber: contract?.internalProjectNumber,
        projectNumber,
        payAppNumber: data?.payApp.payAppNumber,
        maxInvoiceCodeLength: MAX_FOUNDATION_INVOICE_NUMBER_LENGTH,
        billingType: data?.payApp.billingType,
      }),
    [contract?.internalProjectNumber, data, projectNumber]
  )

  const loading = loadingPayApp || loadingAccounts || loadingIncomeTypes

  // Assign the invoice number based on project number and pay app number. This essentially only gets
  // called once when the dialog is first rendered.
  useEffect(() => {
    setInvoiceNumber(defaultInvoiceNumber)
  }, [defaultInvoiceNumber])

  let dialogTitle = t(`${i18nBase}.header`)
  let cancelLabel: string | undefined
  let handleClose: (() => void) | undefined
  let handleSubmit: (() => void) | undefined
  let className: string | undefined
  let actionsLayout: SitelineDialogProps['actionsLayout'] = 'actionsRow'
  let simpleErrorMessage: string | undefined

  if (status.type !== 'notCreated') {
    dialogTitle = ''
    actionsLayout = 'closeIcon'
    className = isWriteSyncInProgress(status) ? undefined : classes.syncSuccess
  } else if (data?.payApp.previousRetentionBilled !== 0) {
    // We don't support this right now
    handleClose = onClose
    dialogTitle = t(`${i18nBase}.create_in_sage`)
    simpleErrorMessage = t(`${i18nBase}.errors.billing_retention`)
  } else if (!hasAnyBilling) {
    handleClose = onClose
    simpleErrorMessage = t(`${i18nBase}.errors.no_billing`)
  } else {
    className = clsx(classes.root, {
      wideSovView:
        ledgerType === LedgerAllocationType.ASSIGN_LEDGER_ACCOUNT_BY_LINE_ITEM && !loading,
    })
    handleClose = onClose
    handleSubmit = () => {
      if (!payload) {
        return
      }
      sync(payload)
    }
  }

  const invoiceNumberTooLong = invoiceNumber.length > MAX_FOUNDATION_INVOICE_NUMBER_LENGTH
  let invoiceNumberHelperText: string | undefined
  if (invoiceNumberTooLong) {
    invoiceNumberHelperText = t(`${i18nBase}.invoice_number_length_long`, {
      maxChars: MAX_FOUNDATION_INVOICE_NUMBER_LENGTH,
    })
  }
  const invoiceNumberError = invoiceNumberTooLong || (!useAutoCode && !invoiceNumber)

  const disableSubmit = useMemo(() => {
    if (invoiceNumberError) {
      return true
    } else if (isMissingDate(invoiceDate)) {
      return true
    } else if (isMissingDate(dueDate)) {
      return true
    } else if (ledgerType === LedgerAllocationType.SINGLE_LEDGER_ACCOUNT && !ledgerAccountId) {
      return true
    } else if (
      ledgerType === LedgerAllocationType.ASSIGN_LEDGER_ACCOUNT_BY_LINE_ITEM &&
      !allLineItemsMapped
    ) {
      return true
    } else if (shouldShowRetentionAccountPicker && !retentionLedgerAccountCode) {
      return true
    } else if (isMissingIncomeType) {
      return true
    }
    return false
  }, [
    allLineItemsMapped,
    dueDate,
    invoiceDate,
    invoiceNumberError,
    isMissingIncomeType,
    ledgerAccountId,
    ledgerType,
    retentionLedgerAccountCode,
    shouldShowRetentionAccountPicker,
  ])

  const showContent = !error && status.type === 'notCreated' && !simpleErrorMessage
  const showLoadingContent = loading && showContent
  const showMainContent = !loading && showContent

  return (
    <SitelineDialog
      title={dialogTitle}
      open={open}
      onClose={handleClose}
      cancelLabel={cancelLabel}
      onSubmit={handleSubmit}
      submitLabel={t('integrations.button.sync')}
      disableSubmit={disableSubmit}
      className={className}
      maxWidth={isWriteSyncInProgress(status) ? 'sm' : 'md'}
      actionsLayout={actionsLayout}
      disableEscapeKeyDown
      fixedTopPosition={STANDARD_FIXED_DIALOG_TOP}
      subtitle={
        showContent && (
          <Link target="_blank" href={HELP_CENTER_URL} underline="none">
            <SitelineText
              variant="h4"
              color="blue50"
              endIcon={<LaunchIcon fontSize="small" />}
              className={classes.help}
            >
              {t(`${i18nBase}.help_center`)}
            </SitelineText>
          </Link>
        )
      }
    >
      {payload && (
        <WriteSyncDialogContent
          integration={integration}
          projectId={projectId}
          onClose={handleClose ?? onClose}
          onBack={resetSync}
          onSyncAgain={() => sync(payload)}
          payload={payload}
          status={status}
        />
      )}

      {simpleErrorMessage && <SitelineText variant="secondary">{simpleErrorMessage}</SitelineText>}

      {showMainContent && (
        <div className="container">
          {multipleTaxCodesWarning && (
            <SitelineAlert severity="warning" className="warning">
              <SitelineText variant="secondary">{multipleTaxCodesWarning}</SitelineText>
            </SitelineAlert>
          )}
          <div className="row">
            <div className="leftSection amountsRow">
              <div className="setting doubleGap">
                <SitelineText variant="secondary" bold color="grey70">
                  {t(`${i18nBase}.amount`)}
                </SitelineText>
                <div>
                  <SitelineText variant="h3" bold>
                    <DollarNumberFormat value={payAppTotal} />
                  </SitelineText>
                </div>
              </div>
              <div className="setting doubleGap">
                <SitelineText variant="secondary" bold color="grey70">
                  {t(`${i18nBase}.amount_retention`)}
                </SitelineText>
                <div>
                  <SitelineText variant="h3" bold>
                    <DollarNumberFormat value={payAppRetention} />
                  </SitelineText>
                </div>
              </div>
              <div className="setting doubleGap">
                <SitelineText variant="secondary" bold color="grey70">
                  {t(`${i18nBase}.amount_tax`)}
                </SitelineText>
                <div>
                  <SitelineText variant="h3" bold>
                    <DollarNumberFormat value={payAppTax} />
                  </SitelineText>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="leftSection">
              <div className="setting">
                <SitelineText variant="secondary" bold color="grey70">
                  {t(`${i18nBase}.invoice_date`)}
                </SitelineText>
                <DatePickerInput
                  value={invoiceDate}
                  onChange={(value) =>
                    setInvoiceDate({
                      ...value,
                      date: value.date?.isValid() ? value.date.clone().endOf('day') : value.date,
                    })
                  }
                  timeZone={timeZone}
                  className="dateInput"
                />
              </div>
              <div className="setting">
                <SitelineText
                  variant="secondary"
                  bold
                  color="grey70"
                  endIcon={
                    <Tooltip
                      title={
                        contract?.paymentTermsType === ContractPaymentTermsType.NET_PAYMENT &&
                        _.isNumber(contract.paymentTerms)
                          ? t(`${i18nBase}.due_date_tooltip_terms`)
                          : t(`${i18nBase}.due_date_tooltip`)
                      }
                      placement="top"
                    >
                      <InfoOutlinedIcon className="tooltip" />
                    </Tooltip>
                  }
                >
                  {t(`${i18nBase}.due_date`)}
                </SitelineText>
                <DatePickerInput
                  value={dueDate}
                  onChange={(value) =>
                    setDueDate({
                      ...value,
                      date: value.date?.isValid() ? value.date.clone().endOf('day') : value.date,
                    })
                  }
                  timeZone={timeZone}
                  className="dateInput"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="leftSection">
              <div className="setting">
                <SitelineText variant="secondary" bold color="grey70">
                  {t(`${i18nBase}.invoice_number`)}
                </SitelineText>
                <Row gap={8}>
                  <FormControl variant="outlined" className="useAutoCode">
                    <Select
                      value={useAutoCode}
                      onChange={(evt) => setUseAutoCode(evt.target.value === 'true')}
                    >
                      <MenuItem value="true">{t(`${i18nBase}.generate_code`)}</MenuItem>
                      <MenuItem value="false">{t(`${i18nBase}.enter_manually`)}</MenuItem>
                    </Select>
                  </FormControl>
                  {!useAutoCode && (
                    <TextField
                      variant="outlined"
                      error={invoiceNumberTooLong}
                      value={invoiceNumber}
                      onChange={(ev) => setInvoiceNumber(ev.target.value)}
                      className="invoiceCode"
                      autoFocus={true}
                    />
                  )}
                  {invoiceNumberHelperText && (
                    <div className="noDefaultNumber">
                      <SitelineText
                        variant="smallText"
                        color={invoiceNumberTooLong ? 'red50' : 'grey50'}
                      >
                        {invoiceNumberHelperText}
                      </SitelineText>
                    </div>
                  )}
                </Row>
              </div>
            </div>
            <div>&nbsp;</div>
          </div>
          {/* The additional dropdowns transition in after the initial data load */}
          <Collapse in appear={loading}>
            <>
              {shouldShowRetentionAccountPicker && (
                <div className="row">
                  <div className="leftSection">
                    <div className="setting">
                      <SitelineText variant="secondary" bold color="grey70">
                        {t(`${i18nBase}.retention_ledger_account`)}
                      </SitelineText>
                      <Autocomplete
                        value={retentionLedgerAccount ?? undefined}
                        disableClearable={true}
                        fullWidth
                        style={{ width: SECTION_WIDTH }}
                        freeSolo={false}
                        options={ledgerAccounts}
                        autoHighlight
                        openOnFocus
                        onChange={(ev, option) => {
                          setRetentionLedgerAccountCode(option.code)
                        }}
                        className={classes.autocomplete}
                        renderInput={(params) => {
                          return (
                            <TextField
                              variant="outlined"
                              placeholder={t(`${i18nBase}.search_accounts`)}
                              {...params}
                            />
                          )
                        }}
                        renderOption={(props, option) => (
                          <li {...props} key={option.integrationAccountId}>
                            <SitelineText variant="secondary">
                              {option.name}
                              <SitelineText variant="secondary" color="grey50">
                                {option.code && ` · ${option.code}`}
                              </SitelineText>
                            </SitelineText>
                          </li>
                        )}
                        getOptionLabel={getLedgerAccountLabel}
                      />
                    </div>
                  </div>
                </div>
              )}
              {isIncomeTypeEnabled && (
                <div className="row">
                  <div className="leftSection">
                    <div className="setting">
                      <SitelineText variant="secondary" bold color="grey70">
                        {t(`${i18nBase}.income_type`)}
                      </SitelineText>
                      <Autocomplete
                        value={incomeTypeNumber ?? undefined}
                        fullWidth
                        disableClearable={true}
                        style={{ width: SECTION_WIDTH }}
                        freeSolo={false}
                        options={incomeTypeNumbers}
                        autoHighlight
                        openOnFocus
                        onChange={(ev, option) => {
                          setIncomeTypeId(option.integrationIncomeTypeNumberId)
                        }}
                        className={classes.autocomplete}
                        renderInput={(params) => {
                          return (
                            <TextField
                              variant="outlined"
                              placeholder={t(`${i18nBase}.search_income_types`)}
                              {...params}
                            />
                          )
                        }}
                        renderOption={(props, option) => (
                          <li {...props} key={option.integrationIncomeTypeNumberId}>
                            <SitelineText variant="secondary">
                              {getIncomeTypeLabel(option)}
                            </SitelineText>
                          </li>
                        )}
                        getOptionLabel={getIncomeTypeLabel}
                      />
                    </div>
                  </div>
                </div>
              )}
            </>
            {payApp && (
              <LedgerAccountOptions
                ledgerAccount={ledgerAccount}
                ledgerAccounts={ledgerAccounts}
                ledgerType={ledgerType}
                ledgerAccountMapping={ledgerAccountMapping}
                onLedgerTypeChange={setLedgerType}
                onLedgerAccountIdChange={setLedgerAccountId}
                onLedgerAccountMappingChange={setLedgerAccountMapping}
                payApp={payApp}
              />
            )}
          </Collapse>
        </div>
      )}

      {showLoadingContent && (
        <div className="container">
          <div className="row">
            <div className="leftSection">
              <div className="setting">
                <SitelineText variant="secondary" bold color="grey70">
                  {t(`${i18nBase}.amount`)}
                </SitelineText>
                <Skeleton variant="rectangular" width={100} height={40} />
              </div>
              <div className="setting">
                <SitelineText variant="secondary" bold color="grey70">
                  {t(`${i18nBase}.invoice_date`)}
                </SitelineText>
                <Skeleton variant="rectangular" width={DROPDOWN_WIDTH} height={40} />
              </div>
            </div>
            <div className="setting">
              <SitelineText variant="secondary" bold color="grey70">
                {t(`${i18nBase}.due_date`)}
              </SitelineText>
              <Skeleton variant="rectangular" width={DROPDOWN_WIDTH} height={40} />
            </div>
          </div>
          <div className="row">
            <div className="leftSection">
              <div className="setting">
                <SitelineText variant="secondary" bold color="grey70">
                  {t(`${i18nBase}.invoice_number`)}
                </SitelineText>
                <Skeleton variant="rectangular" width={200} height={40} />
              </div>
            </div>
          </div>
        </div>
      )}
    </SitelineDialog>
  )
}
