import { CompanyIntegrationMetadataFoundation } from 'siteline-common-all'
import { IntegrationMappingsFoundation } from 'siteline-common-all/src/types/integration'
import {
  IntegrationGeneralLedgerAccount,
  IntegrationIncomeTypeNumber,
  MinimalIntegrationProperties,
} from '../../../common/graphql/apollo-operations'

interface InitialFoundationValuesProps {
  erpIntegration: MinimalIntegrationProperties
  integrationIncomeTypes: IntegrationIncomeTypeNumber[]
  integrationGeneralLedgerAccounts: IntegrationGeneralLedgerAccount[]
}

export function initialFoundationValues({
  erpIntegration,
  integrationIncomeTypes,
  integrationGeneralLedgerAccounts,
}: InitialFoundationValuesProps) {
  const integrationMappings = erpIntegration.mappings as IntegrationMappingsFoundation
  const companyIntegrationMetadata = erpIntegration.companyIntegration
    .metadata as CompanyIntegrationMetadataFoundation

  const incomeTypeId = integrationMappings.incomeTypeId ?? companyIntegrationMetadata.incomeTypeId
  const incomeType =
    integrationIncomeTypes.find((incomeType) => incomeType.incomeTypeNumber === incomeTypeId) ??
    null

  const retentionGlAccountCode =
    integrationMappings.retentionLedgerAccount?.agaveSourceId ??
    companyIntegrationMetadata.retentionLedgerAccount?.agaveSourceId
  const retentionGlAccount =
    integrationGeneralLedgerAccounts.find((account) => account.code === retentionGlAccountCode) ??
    null

  const glAccountId =
    integrationMappings.ledgerAccount?.agaveAccountId ??
    companyIntegrationMetadata.ledgerAccount?.agaveAccountId
  const glAccount =
    integrationGeneralLedgerAccounts.find(
      (account) => account.integrationAccountId === glAccountId
    ) ?? null

  return { glAccount, retentionGlAccount, incomeType }
}
